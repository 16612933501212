import React from 'react'
import moment from 'moment'

interface DateCard {
    text: string;
    date: Date;
}

interface DateCardsProps {
    cards: DateCard[]
}

const DateCards = (props: DateCardsProps) => {

    return (
        <div
            className="DateCards"
        >
            {props.cards.map((card: DateCard) => {
                return (
                    <div className="card" key={card.text}>
                        <span className="text">
                            {card.text}
                        </span>
                        <span className="date">
                            {moment(card.date).format('MMMM Do, YYYY h:mm a') + ' PST'}
                        </span>
                    </div>
                )
            })}
        </div>

    )
}

export default DateCards