import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { AlbumRow, DefaultProps, Divider, Loader } from "bpm-sounds-generic";
import { SoundPackage } from 'api';

const SimilarPacks: React.FC<RouteComponentProps<{ id: string }> & DefaultProps> = (props) => {
    const [packs, setPacks] = useState<SoundPackage[]>()
    const [packName, setPackName] = useState<String>('')

    useEffect(() => {
        setPacks([])
        setPackName('')
        SoundPackageAPI.getSimilarSoundPackages({ id: props.match.params.id }).then(resp => {
            setPacks(resp.data.packs)
            setPackName(resp.data.current.name)
        })
    }, [props.match.params.id])

    return (
        <>
            <Divider small />
            {
                !packs || packs.length < 1
                    ? <Loader centered style={{ marginTop: '10%' }} />
                    : <AlbumRow
                        {...props}
                        context={'newest_packs'}
                        title={`Packs Similar to ${packName}`}
                        soundPackages={packs}
                        defaultExpanded={true}
                    />

            }
            <Divider />
        </>
    )
}

export default withRouter(SimilarPacks)