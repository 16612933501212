import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { ContestAPI } from '../../api/endpoints/contest'
import { ContestComment } from '../../api/models/contest'
import { EDIT_SVG, TRASH_SVG, VERIFIED_BLUE_SVG, USER_PLACEHOLDER } from "bpm-sounds-generic/assets"
import { Divider, usernameOrFullName, UserContextConsumer } from 'bpm-sounds-generic'
import CommentBox from './CommentBox'

interface CommentCards {
    contestId: string;
}

const CommentCards = (props: CommentCards) => {
    const [comments, setComments] = useState<ContestComment[]>([])

    const commentCards = useRef<HTMLDivElement>(null)
    const [commentsEnd, setCommentsEnd] = useState<boolean>(false)
    const [editingComment, setEditingComment] = useState<ContestComment>()

    const [height, setHeight] = useState<number>();
    const [page, setPage] = useState<number>(1)


    // Get comments from DB
    useEffect(() => {
        ContestAPI.getContestComments(props.contestId, { skip: 0, limit: 4 }).then(comments => {
            setComments(comments)
        })
    }, [props.contestId])


    // On view more clicked, append 4 comments
    useEffect(() => {
        if (comments) {
            ContestAPI.getContestComments(props.contestId, { skip: (page - 1) * 4, limit: 4 }).then(newComments => {
                let updateCards = [...comments]
                updateCards.push(...newComments)

                updateCards.unique('id')
                setComments(updateCards)

                if (newComments.length < 4) {
                    setCommentsEnd(true)
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [page])


    // Alter height for Animateheight
    useEffect(() => {
        setHeight(commentCards.current?.clientHeight)
    })

    const deleteComment = (id: string, index: number) => {
        ContestAPI.deleteComment(id).then(newCard => {
            if (comments) {
                setComments(comments.filter(c => c.id != id))
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const editComment = (commen: ContestComment) => {
        setEditingComment(commen)
    }
    return (
        <>
            <CommentBox onCommentSubmit={(comment) => {
                let updateCards = [...comments]
                updateCards.unshift(comment)
                setComments(updateCards)
            }} contestId={props.contestId} />
            <Divider height={42} />
            {comments && <div className="CommentCards">
                <AnimateHeight
                    duration={900}
                    height={height}
                >
                    <div ref={commentCards}>
                        {
                            comments.map((comment, index) => {
                                if (comment.id == editingComment?.id) {
                                    return <div className="comment-card" key={comment.id}>
                                        <CommentBox editingComment={editingComment} onCommentSubmit={(comment) => {
                                            if (editingComment) {
                                                let updateCards = [...comments]
                                                let index = updateCards.findIndex(c => c.id == editingComment.id)
                                                updateCards[index] = comment
                                                setComments(updateCards)
                                                setEditingComment(undefined)
                                            } else {
                                                let updateCards = [...comments]
                                                updateCards.unshift(comment)
                                                setComments(updateCards)
                                            }

                                        }} contestId={props.contestId} />
                                    </div>
                                }
                                const { profile_image_thumbnail_url } = comment.user
                                return (
                                    <div className="comment-card" key={comment.id}>
                                        {profile_image_thumbnail_url ? <img className='image' src={profile_image_thumbnail_url} alt="avi" /> : <img className='image' src={USER_PLACEHOLDER} alt="avi" />}
                                        <div className="text">
                                            <span className="username">{usernameOrFullName(comment.user)}</span>
                                            <img src={VERIFIED_BLUE_SVG} alt="" className={`verified ${comment.user.chat_verified ? '' : 'hide'}`} />
                                            <span className="date">{moment(comment.created_at).format('MMMM Do, YYYY')}</span>
                                            <div className="description">{comment.comment}</div>
                                        </div>
                                        <div className="buttons">
                                            <UserContextConsumer>{user => {
                                                if (user.id != comment.user.id) {
                                                    return null
                                                }
                                                return <>
                                                    <img className='edit' width={30} height={30} src={EDIT_SVG} alt="edit" onClick={() => {
                                                        editComment(comment)
                                                    }} />
                                                    <img className='trash' width={30} height={30} src={TRASH_SVG} alt="delete" onClick={() => {
                                                        deleteComment(comment.id, index)
                                                    }} />
                                                </>
                                            }}</UserContextConsumer>

                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </AnimateHeight>
                {
                    <div className={`view-more ${commentsEnd ? 'end' : ''}`} onClick={() => {
                        setPage(page + 1)
                    }}>
                        View More
                    </div>
                }
                <div className={`end-of-comments ${commentsEnd ? 'show' : ''}`}>
                    End of Comments
                </div>
            </div>
            }
        </>
    )
}

export default CommentCards