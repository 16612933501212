import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { DefaultProps } from "bpm-sounds-generic";
import { DriveAPI } from '../api/endpoints/drive';
import { Drive } from '../api/models/drive';
import { Button, EditModeContextConsumer, Divider } from 'bpm-sounds-generic';
import { BannerRenderer } from "bpm-sounds-generic";
import PopupCreateCuratedset from 'components/edit/popup/PopupCreateCuratedSetPopup';
import { Analytics } from 'manager/AnalyticsManager';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'

const DriveDetail: React.FC<RouteComponentProps<{ driveId: string, name: string }> & DefaultProps> = (props) => {
    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({ drive_id: props.match.params.driveId })
    const [drive, setDrive] = React.useState<Drive>()

    React.useEffect(() => {
        setDrive(undefined)
        setFilter({ drive_id: props.match.params.driveId })
        DriveAPI.getDrive(props.match.params.driveId).then(setDrive)
    }, [props.match.params.driveId])

    return (
        <>
            <Divider small />
            <BannerRenderer location={'drive'} onItemClick={(type, id) => Analytics.trackClick(type, id)} />
            <CreateManagedSoundList
                key={filter.drive_id} {...props}
                pagingMode='manual'
                drive={drive} title={drive?.name || ' '}
                context={'drive:' + props.match.params.driveId}
                apiFunc={SoundAPI.getSounds}
                filter={filter} onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }} hideFilterKeys={['drive_id']} leftHeaderActionRenderer={() => {
                    return <EditModeContextConsumer>{edit => {
                        if (edit) {
                            return <EditMode driveId={filter.drive_id!} />
                        }
                        return null
                    }}</EditModeContextConsumer>
                }} />
        </>
    )
}
const EditMode = (props: { driveId: string }) => {
    const [show, setShow] = React.useState(false)
    return <>
        <Button buttonText='Create Curated Set'
            style={{ display: 'inline', marginLeft: 16 }}
            onClick={() => {
                setShow(true)
            }}
            variation={'tag'}
            border={true}

        />
        <PopupCreateCuratedset driveId={props.driveId} closed={!show} onClosed={() => {
            setShow(false)
        }} />
    </>
}
export default withRouter(DriveDetail)