import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { SoundAPI } from '../api/endpoints/sound';
import { DefaultProps } from "bpm-sounds-generic";
import { SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { ManagedAlbumRow, Divider, ManagedSoundList } from 'bpm-sounds-generic';
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'

const Favorites: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({})

    return (
        <>
            <Divider small />
            <BannerRenderer location={'favorites'} onItemClick={(type, id) => Analytics.trackClick(type, id)} />
            <ManagedAlbumRow {...props} context={'favorite_packs'} apiFunc={SoundPackageAPI.getLikedSoundPackages} title='Favorite Packs' />
            <Divider />
            <CreateManagedSoundList {...props} title='Favorite Sounds' filter={filter} pagingMode='manual' onFilterChange={(newFilter) => {
                setFilter((filter) => {
                    return { ...filter, ...newFilter }
                })
            }} apiFunc={SoundAPI.getLikedSounds} context={'favorites'} hideTagFilter />
        </>
    )
}

export default withRouter(Favorites)