import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { ContestAPI } from '../../api/endpoints/contest'
import { Contest, ContestSubmission } from '../../api/models/contest'
import { FAV_SVG, SHARE_SVG, FAV_FILLED_SVG, PLAY_RUBY_SVG, PLAY_SMALL_WHITE_SVG, PAUSE_RUBY_SVG } from "bpm-sounds-generic/assets"
import { PlayerContext, PlayerContextConsumer } from 'bpm-sounds-generic'
import { AddToHistory, DynamicPlaylist, FullPlaybackMediaInfo, PlaybackManager, PlayerState, useWindowSize, ShareModal, WaveForm, usernameOrFullName } from "bpm-sounds-generic"
import smoothscroll from 'smoothscroll-polyfill';
import { useLocation } from 'react-router'
import * as qs from 'query-string'

interface SubmissionCards {
    contest: Contest;
}

const SubmissionCards = (props: SubmissionCards) => {
    smoothscroll.polyfill()
    const location = useLocation()
    const sharedSubmissionRef = useRef<HTMLDivElement>(null)

    const [submissions, setSubmissions] = useState<ContestSubmission[]>()
    const [submissionsEnd, setSubmissionsEnd] = useState<boolean>(false)

    const submissionCards = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState<number>();
    const { width } = useWindowSize()

    const [page, setPage] = useState<number>(1)
    const [showModal, setShowModal] = useState(false);

    const [shareID, setShareID] = useState<string>()
    const [submissionQuery, setSubmissionQuery] = useState<string | undefined>(qs.parse(location.search).submission_id as string)

    const internallist = React.useRef(new DynamicPlaylist([], {
        history_mode: AddToHistory.NONE
    }, 'contest_submissions', false));


    // Get submissions from DB
    useEffect(() => {
        ContestAPI.getContestSubmissions(props.contest.id, { limit: 4, skip: 0 }, submissionQuery).then(submissions => {
            setSubmissions(submissions)
        })
    }, [])

    // Call API and set submissions to render
    useEffect(() => {
        if (submissions) {
            ContestAPI.getContestSubmissions(props.contest.id, { skip: (page - 1) * 4, limit: 4 }).then(newSubmissions => {
                let updateCards = [...submissions]
                updateCards.push(...newSubmissions)

                updateCards.unique('id')
                setSubmissions(updateCards)

                if (newSubmissions.length < 4) {
                    setSubmissionsEnd(true)
                }
            }).catch((err) => {
                console.log(err)
            })
        }
    }, [page])

    useEffect(() => {
        setHeight(submissionCards.current?.clientHeight)
    })

    // Handle playback
    useEffect(() => {
        internallist.current.replace((submissions || []).map(submission => {
            return {
                sound: PlaybackManager.contestSubmissionToPlayableSound(submission),
                playlist: internallist.current
            } as FullPlaybackMediaInfo
        }));

        PlaybackManager.getInstance().getContentManager().replaceLists(internallist.current);
    }, [submissions])

    // Scroll to submission for sharelink
    useEffect(() => {
        if (sharedSubmissionRef.current && submissionQuery) {
            const headerOffset = document.querySelector('.HeaderBar')?.clientHeight;
            const elementPosition = sharedSubmissionRef.current.getBoundingClientRect().top;
            const offsetPosition = elementPosition - headerOffset!;
            document.scrollingElement!.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            })
            const submission = submissions?.find(s => s.id == submissionQuery)
            if (submission)
                togglePlay(undefined, submission)
            setSubmissionQuery(undefined)
        }
    }, [submissions])


    const updateLikeUnlike = (liked: boolean, id: string, index: number) => {
        if (liked) {
            ContestAPI.unLikeSubmission(id).then(newCard => {
                if (submissions) {
                    let updateCards = [...submissions]
                    updateCards[index] = newCard
                    setSubmissions(updateCards)
                }

            }).catch(err => {
                console.log(err)
            })
        }
        else {
            ContestAPI.likeSubmission(id).then(newCard => {
                if (submissions) {
                    let updateCards = [...submissions]
                    updateCards[index] = newCard
                    setSubmissions(updateCards)
                }
            }).catch(err => {
                console.log(err)
            })
        }
    }

    const recordStream = (id: string) => {
        ContestAPI.recordSubmissionStream(id).then(newCard => {
            if (submissions) {
                let updateCards = [...submissions]
                let index = updateCards.findIndex(s => s.id == id)!
                updateCards[index] = newCard
                setSubmissions(updateCards)
            }
        }).catch(err => {
            console.log(err)
        })
    }

    const togglePlay = (playerContext: PlayerContext | undefined, submission: ContestSubmission) => {
        let mediaSelected = false;

        if (playerContext && playerContext.media && playerContext.state >= PlayerState.Loading) {
            mediaSelected = PlaybackManager.isSoundEqualContestSubmission(submission, playerContext.media!.sound) !== false
        }
        if (mediaSelected) {
            PlaybackManager.getInstance().pause();
        } else {
            PlaybackManager.getInstance().getContentManager().replaceLists(internallist.current);
            PlaybackManager.getInstance().startTrack(internallist.current!.tracks().find(t => PlaybackManager.isSoundEqualContestSubmission(submission, t.sound))!);
            recordStream(submission.id)
        }
    }

    return (
        !submissions ? null :
            <div className="SubmissionCards">
                <AnimateHeight
                    duration={900}
                    height={height}
                >
                    <div ref={submissionCards}>
                        {submissions.map((submission, index) => {
                            const { profile_image_thumbnail_url, id, username } = submission.user
                            const shareButton = document.getElementById(submission.id)
                            return (
                                <div key={submission.id} className="card" ref={submission.id === submissionQuery ? sharedSubmissionRef : null}>
                                    <div className="top-container">
                                        <PlayerContextConsumer>{context => {
                                            let mediaSelected = false;
                                            if (context.media && context.state >= PlayerState.Loading) {
                                                mediaSelected = PlaybackManager.isSoundEqualContestSubmission(submission, context.media!.sound) !== false
                                            }
                                            return <img className="play" width={27} src={mediaSelected ? PAUSE_RUBY_SVG : PLAY_RUBY_SVG} alt="play" onClick={() => {
                                                togglePlay(context, submission)
                                            }} />
                                        }}</PlayerContextConsumer>
                                        <div className="wav">
                                            <WaveForm sound={PlaybackManager.contestSubmissionToPlayableSound(submission)} playlist={internallist.current} />
                                        </div>
                                        <div className="title">{submission.title}</div>
                                        <div className="count-wrapper">
                                            <div className="play-count">
                                                <img src={PLAY_SMALL_WHITE_SVG} alt="" />
                                                <span className="count">{submission.stream_count}</span>
                                            </div>
                                            <div className="heart-count">
                                                <img src={FAV_FILLED_SVG} alt="" />
                                                <span className="count">{submission.like_count}</span>
                                            </div>
                                        </div>

                                        <div className="buttons">
                                            <img className={`heart ${submission.liked ? 'remove-fill' : ''}`} width={27} height={27} src={submission.liked ? FAV_FILLED_SVG : FAV_SVG} alt="like" onClick={() => {
                                                updateLikeUnlike(submission.liked, submission.id, index)
                                            }} />
                                            <img id={submission.id} className="share" width={27} height={27} src={SHARE_SVG} alt="share" onClick={() => {
                                                setShareID(submission.id)
                                                setShowModal(!showModal)
                                            }} />
                                            {
                                                shareID === submission.id ?
                                                    <ShareModal
                                                        shareTitle={submission.title}
                                                        showModal={showModal}
                                                        onShowModalChange={(change) => {
                                                            setShowModal(change)
                                                        }}
                                                        shareAnchor={shareButton}
                                                        shareURL={`${window.location.href}?submission_id=${submission.id}`}
                                                        remove={['tumblr', 'reddit']}
                                                    />
                                                    : null
                                            }
                                        </div>
                                    </div>
                                    <div className="bottom-container">
                                        {profile_image_thumbnail_url ? <img className='image' src={profile_image_thumbnail_url} /> : <div className='image' />}
                                        <div className="text">
                                            <span className="username">{usernameOrFullName(submission.user)}</span>
                                            <span className="date">{moment(submission.created_at).format('MMMM Do, YYYY')}</span>
                                            <div className="description">{submission.description}</div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </AnimateHeight>
                {
                    <div className={`view-more ${submissionsEnd ? 'end' : ''}`} onClick={() => {
                        setPage(page + 1)
                    }}>
                        View More
                    </div>
                }
                <div className={`end-of-comments ${submissionsEnd ? 'show' : ''}`}>
                    End of Submissions
                </div>
            </div>

    )
}

export default SubmissionCards