import React from 'react'
import { SOCIAL_BLACK_FB, SOCIAL_BLACK_LINKEDIN, SOCIAL_BLACK_TWITTER, SOCIAL_EMAIL, SOCIAL_LINK } from "bpm-sounds-generic/assets"
import { Button } from 'bpm-sounds-generic'
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
} from "react-share";
import { useHistory } from 'react-router';
import { shareLink } from 'bpm-sounds-generic';
import { Giveaway } from 'api/models/giveaway';

interface HeaderProps {
    giveaway: Giveaway
    onEntry: () => void
}

const Header = (props: HeaderProps) => {
    const { artwork_url, title, artwork_gradient_colors, terms_pdf_url, entry_deadline, slug } = props.giveaway
    const shareUrl = window.location.href
    const history = useHistory()

    let style: React.CSSProperties = {}
    if (artwork_gradient_colors) {
        const top_1 = 'black'
        const top_2 = artwork_gradient_colors.split(', ')[0]
        const top_3 = artwork_gradient_colors.split(', ')[1]
        const background =
            `linear-gradient(-90deg, ${top_1} 0%, ${top_2} 50%, ${top_3} 100%)`
        style = ({ background: background })
    }
    return (
        <div className="Contest-Header" style={style}>
            <img className="artwork" src={artwork_url} alt="" />
            <div className="right-content">
                <div className="title">{title}</div>
                <div className="big-buttons">

                    <Button disabled={new Date(entry_deadline).getTime() < (new Date()).getTime()}
                        buttonText="Enter Giveaway" backgroundColor="#fff"
                        imgWidth={'22px'} fontSize={'16px'} textColor={'#1A1A1A'}
                        onClick={() => props.onEntry()} />
                </div>
                <div className="share-container">
                    <span className="share-text">Share:</span>
                    <FacebookShareButton
                        url={shareUrl}
                        quote={'BPM Create: ' + title}
                        className="share-button fb"
                    >
                        <img src={SOCIAL_BLACK_FB} alt="facebook" />
                    </FacebookShareButton>

                    <TwitterShareButton
                        url={shareUrl}
                        title={'BPM Create: ' + title}
                        className="share-button twitter"
                    >
                        <img src={SOCIAL_BLACK_TWITTER} alt="twitter" />
                    </TwitterShareButton>

                    <LinkedinShareButton
                        url={shareUrl}
                        title={'BPM Create: ' + title}
                        className="share-button linkedin"
                    >
                        <img src={SOCIAL_BLACK_LINKEDIN} alt="linkedin" />
                    </LinkedinShareButton>
                    <EmailShareButton
                        url={shareUrl}
                        subject={'BPM Create: ' + title}
                        body={'Check out this giveaway on BPMCreate: '}
                        className="share-button email"
                    >
                        <img src={SOCIAL_EMAIL} alt="facebook" />
                    </EmailShareButton>
                    <button className="share-button link" onClick={() => {
                        shareLink(window.location.href);
                    }}>
                        <img src={SOCIAL_LINK} alt="facebook" />
                    </button>
                    <a className='terms' href={terms_pdf_url} target="__blank" >Terms & Conditions</a>
                </div>
            </div>
        </div>
    )
}

export default Header