import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { DefaultProps } from "bpm-sounds-generic";
import { SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { CuratedPackage } from '../api/models/curated';
import { CuratedAPI } from '../api/endpoints/curated';
import { AlbumRow, Divider } from 'bpm-sounds-generic';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'

const FreeSounds: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({ only_free: true })
    const [loading, setLoading] = React.useState(true)
    const [packs, setPacks] = React.useState<CuratedPackage[]>([])

    React.useEffect(() => {
        setLoading(true)
        Promise.all([
            CuratedAPI.getFreeCurated({ limit: 20, skip: 0 }),
            SoundPackageAPI.getFreeSoundPackages({ limit: 20, skip: 0 })
        ]).then(([curated, packs]) => {
            setPacks((packs.data as CuratedPackage[]).concat(curated.data))
            setLoading(false)
        })
    }, [])
    return (
        <>
            <Divider small />
            <AlbumRow {...props} context={'free_packs'} title='Free Packs' soundPackages={packs} loading={loading} />
            <Divider />

            <CreateManagedSoundList
                hideFilterKeys={['only_free']}
                pagingMode='manual'
                {...props}
                title='Free Sounds'
                filter={filter}
                onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }}
                apiFunc={SoundAPI.getSounds} context={'free'} />
        </>
    )
}


export default withRouter(FreeSounds)