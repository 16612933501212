import { AuthenticatedRequest, HTTPMethod, APIVersion, AuthenticatedFileRequest } from "../requests/request";
import { Contest, ContestComment, ContestSubmission } from "../models/contest";
import { PagingInfo } from "./types";

export class ContestAPI {

    public static getContest(): Promise<{ current: Contest[], past: Contest[] }> {
        let apiRequest = new AuthenticatedRequest('/contests/all', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }
    /**
     * Important: When using paging (View More), make sure to call array.unique('id') after appending the new comments to the array. It can happen that comments are duplicacted!
     * @param id 
     * @param paging 
     */
    public static getContestComments(id: string, paging: PagingInfo): Promise<ContestComment[]> {
        let apiRequest = new AuthenticatedRequest('/contest/' + id + '/comments', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static commentContest(id: string, comment: string): Promise<ContestComment> {
        let apiRequest = new AuthenticatedRequest('/contest/' + id + '/comment', HTTPMethod.POST, APIVersion.v1_0, undefined, { comment });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static editComment(commentId: string, comment: string): Promise<ContestComment> {
        let apiRequest = new AuthenticatedRequest('/contest/comments/' + commentId, HTTPMethod.PUT, APIVersion.v1_0, undefined, { comment });

        return apiRequest.send().then((res) => {
            return res.data
        });
    }

    public static likeComment(commentId: string): Promise<ContestComment> {
        let apiRequest = new AuthenticatedRequest('/contest/comments/' + commentId + '/like', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static deleteComment(commentId: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest('/contest/comments/' + commentId, HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then(() => {
            return void 0;
        });
    }

    public static unLikeComment(commentId: string): Promise<ContestComment> {
        let apiRequest = new AuthenticatedRequest('/contest/comments/' + commentId + '/like', HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    /**
     * Important: When using paging (View More), make sure to call array.unique('id') after appending the new comments to the array. It can happen that comments are duplicacted!
     * @param id 
     * @param paging 
     */
    public static getContestSubmissions(contestId: string, paging: PagingInfo, submissionId?: string): Promise<ContestSubmission[]> {
        let apiRequest = new AuthenticatedRequest('/contest/' + contestId + '/submissions', HTTPMethod.GET, APIVersion.v1_0, { ...paging, id: submissionId });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static likeSubmission(submissionId: string): Promise<ContestSubmission> {
        let apiRequest = new AuthenticatedRequest('/contest/submissions/' + submissionId + '/like', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static unLikeSubmission(submissionId: string): Promise<ContestSubmission> {
        let apiRequest = new AuthenticatedRequest('/contest/submissions/' + submissionId + '/like', HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static recordSubmissionStream(submissionId: string): Promise<ContestSubmission> {
        let apiRequest = new AuthenticatedRequest('/contest/submissions/' + submissionId + '/stream', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static recordDownloadStems(contestId: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest('/contest/' + contestId + '/download-stems', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static submit(contestId: string, title: string, file: any, description?: string): Promise<ContestSubmission> {
        let apiRequest = new AuthenticatedFileRequest('/contest/' + contestId + '/submit', HTTPMethod.POST, APIVersion.v1_0, undefined, { title, file, description });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }
}