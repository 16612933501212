import { EventEmitter } from "events";
import { DriveAPI } from "../api/endpoints/drive";
import { Drive } from "../api/models/drive";
import * as toastr from 'toastr'
import { Sound } from "../api";
import { Analytics } from "./AnalyticsManager";

export const DRIVE_LOADED_EVENT = 'drives-loaded'

export class DriveManager extends EventEmitter {
    private static _instance = new DriveManager()
    drives: Drive[] = [];

    public static instance() {
        return DriveManager._instance;
    }

    private constructor() {
        super();
    }

    public loadDrives() {
        return DriveAPI.getDrives().then((drives) => {
            this.drives = drives;
            this.emit(DRIVE_LOADED_EVENT, this.drives)
        })
    }

    public createDrive(name: string) {
        return DriveAPI.createDrive(name).then(drive => {
            this.drives.push(drive);
            this.emit(DRIVE_LOADED_EVENT, this.drives)
        })
    }

    public deleteDrive(id: string) {
        return DriveAPI.deleteDrive(id).then(() => {
            this.drives = this.drives.filter(drive => drive.id !== id)
            this.emit(DRIVE_LOADED_EVENT, this.drives)
        })
    }

    public addSoundToDrive(sound: Sound, drive: Drive, multiple?: boolean) {
        Analytics.trackClick('add_to_drive_sound', sound.id.toString())
        return DriveAPI.addToDrive(drive.id, sound.id).then(() => {
            if (!multiple) toastr.success('Added ' + sound.name + ' to ' + drive.name)
        })
    }

    public removeFromDrive(drive_id: string, sound_id: string) {
        return DriveAPI.removeFromDrive(drive_id, sound_id).then(() => {
            this.emit(DRIVE_LOADED_EVENT, this.drives)
        })
    }


    public sortDrives(drives: Drive[]) {
        return drives.sort((drive1: Drive, drive2: Drive) => new Date(drive1.created_at).getTime() - new Date(drive2.created_at).getTime())
    }
}
