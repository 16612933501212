import { EventEmitter } from "events";
import { CreditInfo } from "../api/models/credit";
import { UsersAPI } from "../api/endpoints/user";

export const CREDIT_LOADED_EVENT = 'credits-loaded'

export class CreditManager extends EventEmitter {
    private static _instance = new CreditManager()
    public info: CreditInfo = { promo: 0, regular: 0, hasAdminAccess: false };

    public static instance() {
        return CreditManager._instance;
    }

    private constructor() {
        super();
    }

    public loadCredits() {
        return UsersAPI.getCredits().then((info) => {
            this.info = info;
            this.emit(CREDIT_LOADED_EVENT, this.info)
        })
    }
}
