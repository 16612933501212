import * as React from 'react'
import { RouteComponentProps, withRouter, useHistory, useLocation } from "react-router";
import { SoundPackageQueryAttributes, SoundDisplayAttributes, PagingInfo, PagingInfoResponse, SoundQueryAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { SoundPackage } from '../api/models/soundpackage';
import * as hash from 'object-hash'
import { DefaultProps, useDeepCompareEffect, Divider, ManagedAlbumRow } from "bpm-sounds-generic";
import { parseSearchFromQuery, transferSearchToQuery } from 'util/query-param';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'

const Search: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({})
    const [albumHashFilter, setAlbumHashFilter] = React.useState(hash.MD5({ keyType: undefined, showHalfTime: undefined }))
    const history = useHistory()
    const location = useLocation()

    React.useEffect(() => {
        setFilter(parseSearchFromQuery(location))
    }, [])

    React.useEffect(() => {
        setFilter((filter) => ({ ...filter, ...location.state }))
    }, [location.state])

    useDeepCompareEffect(() => {
        transferSearchToQuery(history, filter)
        setAlbumHashFilter(hash.MD5({ ...filter, keyType: undefined, showHalfTime: undefined } as SoundPackageQueryAttributes & SoundDisplayAttributes))
    }, [filter])

    const soundPackageTransform = (filter: SoundPackageQueryAttributes) => {
        return (paging: PagingInfo, token?: string): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse, token?: string }> => {
            return SoundPackageAPI.getSoundPackages(filter, paging, token).then((data) => {
                return { data: data.data, pagination: data.pagination, token: data.token }
            })
        }
    }

    return (
        <>
            <Divider small />
            <ManagedAlbumRow {...props} title={'Sound Packs'} context={'search_album'} apiFunc={soundPackageTransform(filter)} hashToWatch={albumHashFilter} />
            <Divider />
            <CreateManagedSoundList {...props} context={'search'} apiFunc={SoundAPI.getSounds} pagingMode='manual'
                filter={filter} onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }} />
        </>
    )
}

export default withRouter(Search)