import { SubGenre } from "./sub_genre";
import { Label } from "./label";
import { Genre } from "./genre";
import { Artist } from "./artist";
import moment from "moment";

export interface SoundPackage {
    id: string
    created_at: string
    updated_at: string

    catalog_id: string
    slug: string
    name: string
    description: string
    artist: string

    approved?: boolean

    is_favorited: boolean

    artwork_url: string
    demo_file_url?: {
        mp3: string
        hls: string
    }
    demo_wave?: {
        red: string
        gray: string
        light: string
    }
    demo_file_url_2?: {
        mp3: string
        hls: string
    }
    demo_wave_2?: {
        red: string
        gray: string
        light: string
    }

    video_url?: string

    SubGenres?: SubGenre[]
    Label?: Label
    Genre?: Genre
    Artists?: Artist[]

    trending_status: number
    logo_gradient_colors?: string;

    __typename: 'SoundPackage'
}

export function isSoundPackagePending(pack: SoundPackage) {
    return pack.approved === undefined ? false : !pack.approved || (pack.created_at ? moment(pack.created_at).isAfter(moment()) : false)
}