import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { DefaultProps } from "bpm-sounds-generic";
import { SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { ManagedAlbumRow, Divider } from 'bpm-sounds-generic';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'


const MyDownloads: React.FC<RouteComponentProps & DefaultProps> = (props) => {
    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({})

    return (
        <div className="MyDownloads">
            <Divider small />
            <ManagedAlbumRow {...props} context={'downloaded_packs'} title='My Packs' apiFunc={SoundPackageAPI.getDownloadedSoundPackages} />
            <Divider />
            <CreateManagedSoundList pagingMode='manual' {...props} title='My Sounds' filter={filter} onFilterChange={(newFilter) => {
                setFilter((filter) => {
                    return { ...filter, ...newFilter }
                })
            }} apiFunc={SoundAPI.getDownloadedSounds} context={'mylibrary'} hideTagFilter />
        </div>
    )
}


export default withRouter(MyDownloads)