import React, { useState } from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { SoundAPI } from '../api/endpoints/sound';
import { SoundPackage, Genre } from '../api';
import { PagingInfo, PagingInfoResponse, SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { ManagedAlbumRow, Divider, DefaultProps } from 'bpm-sounds-generic';
import { Analytics } from 'manager/AnalyticsManager';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'

const GenreDetail: React.FC<RouteComponentProps<{ name: string }> & DefaultProps> = (props) => {
    const [genre, setGenre] = useState<Genre>()
    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({ genre: decodeURIComponent(props.match.params.name) })

    React.useEffect(() => {
        setFilter({ ...filter, ...props.location.state, genre: decodeURIComponent(props.match.params.name) })
    }, [props.match.params.name])


    const soundPackageTransform = (slug: string) => {
        return (paging: PagingInfo): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> => {
            return SoundPackageAPI.getSoundPackagesByGenre(slug)(paging).then((data) => {
                setGenre(data.data.genre)
                Analytics.trackView('genre', genre?.name)
                return { data: data.data.packs, pagination: data.pagination }
            })
        }
    }


    return (
        <>
            <Divider small />
            <ManagedAlbumRow {...props} context={'genre_packs'}
                title={genre ? genre.name : ''}
                apiFunc={soundPackageTransform(decodeURIComponent(props.match.params.name))} />
            <Divider />
            <CreateManagedSoundList {...props}
                context={'genre:' + props.match.params.name}
                pagingMode='manual'
                apiFunc={SoundAPI.getSounds}
                filter={filter}
                onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }} hideFilterKeys={['genre']} />
        </>
    )
}

export default withRouter(GenreDetail)