import React, { useState, useRef } from "react";
import { TRIANGLE_DOWN, ELLIPSES_SVG, PAUSE_RUBY_SVG, PLAY_RUBY_SVG } from "bpm-sounds-generic/assets";
import { Loader } from 'bpm-sounds-generic';
import { DefaultProps } from "bpm-sounds-generic";
import { DynamicPlaylist, AddToHistory, PlaybackManager, FullPlaybackMediaInfo, PlayerState } from "bpm-sounds-generic"
import { PlayerContextConsumer, PlayerContext } from "bpm-sounds-generic"
import { Popover } from "@material-ui/core";
import { DropdownMenu } from "bpm-sounds-generic";
import { useHistory } from "react-router";
import { CuratedPackageWrapper } from "bpm-sounds-generic"
import { CuratedPackage } from "../api/models/curated";

export interface TrendingProps extends DefaultProps {
    title?: string;
    viewAll?: boolean;
    loading?: boolean;
    subtitle?: string; // description underneath
    soundsPacks?: CuratedPackage[];
}

const Trending = (props: TrendingProps) => {
    const history = useHistory();
    const [menuAnchro, setMenuAnchor] = useState<HTMLImageElement>()
    const [menuSoundId, setMenuSoundId] = useState<string>()

    const listItem = useRef<HTMLLIElement>(null)
    const internallist = React.useRef(new DynamicPlaylist([], {
        history_mode: AddToHistory.ALL
    }, 'trending', false));

    React.useEffect(() => {
        internallist.current.replace((props.soundsPacks || []).reduce((prev, pack) => {
            if (pack.demo_file_url) {
                prev.push({
                    sound: PlaybackManager.packageToPlayableSound(pack, 1),
                    playlist: internallist.current
                } as FullPlaybackMediaInfo);
            }
            if (pack.demo_file_url_2) {
                prev.push({
                    sound: PlaybackManager.packageToPlayableSound(pack, 2),
                    playlist: internallist.current
                } as FullPlaybackMediaInfo);
            }
            return prev
        }, [] as FullPlaybackMediaInfo[]));
        PlaybackManager.getInstance().getContentManager().replaceLists(internallist.current);
    }, [props.soundsPacks])

    const togglePlay = (pack: CuratedPackage, playerContext: PlayerContext, demo: 1 | 2 = 1) => {
        let mediaSelected = false;

        if (playerContext.media && playerContext.state >= PlayerState.Loading) {
            mediaSelected = PlaybackManager.isSoundEqualSoundPackage(pack, playerContext.media!.sound) !== false
        }

        if (mediaSelected) {
            PlaybackManager.getInstance().pause();
        } else {
            PlaybackManager.getInstance().getContentManager().replaceLists(internallist.current);
            PlaybackManager.getInstance().startTrack(internallist.current.tracks().find(a => PlaybackManager.isSoundEqualSoundPackage(pack, a.sound) == demo)!);
        }
    }

    return (
        <div className="Trending container">
            <div className="header-text">
                <span className="title">{props.title || 'Trending'}</span>
                <span className="view" onClick={() => history.push('/trends')} >
                    View All
                </span>
            </div>
            <div className="grid">
                <ul>
                    {props.soundsPacks && props.soundsPacks.map((sound, index) => {
                        return <CuratedPackageWrapper key={sound.id} curated={sound}>{(soundPackage) => {
                            return (
                                <PlayerContextConsumer >{playerContext => {
                                    let mediaSelected = false;

                                    if (playerContext.media && playerContext.state >= PlayerState.Loading) {
                                        mediaSelected = PlaybackManager.isSoundEqualSoundPackage(soundPackage, playerContext.media!.sound) !== false
                                    }
                                    return <li key={index} ref={listItem} className={mediaSelected ? 'playing' : ''}>
                                        <img
                                            className="play-button no-select"
                                            src={mediaSelected ? PAUSE_RUBY_SVG : PLAY_RUBY_SVG}
                                            alt="play/pause"
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                togglePlay(soundPackage, playerContext)
                                            }}>
                                        </img>

                                        <span className="number no-select">{index + 1}</span>
                                        <span className={"triangle " + (soundPackage.trending_status > 0 || index == 0 ? 'up' : '')}><img src={TRIANGLE_DOWN} alt="" /></span>
                                        <div
                                            className="image-container"
                                            onClick={() => {
                                                if (soundPackage.__typename == 'Curated') {
                                                    props.onCuratedClick(soundPackage)
                                                } else
                                                    props.onSoundPackageClick(soundPackage)
                                            }}>
                                            <img className="image" src={soundPackage.artwork_url} alt="" />
                                        </div>
                                        <div className="content-container">
                                            <div className="text-container">
                                                <div className="title">{soundPackage.name}</div>
                                                <div className="description">{soundPackage.artist}</div>
                                            </div>
                                        </div>

                                        <img className="ellipses" src={ELLIPSES_SVG} onClick={(event) => {
                                            setMenuAnchor(event.currentTarget)
                                            setMenuSoundId(soundPackage.id)
                                        }} />
                                        <Popover open={menuSoundId === soundPackage.id} onClick={(e) => {
                                            e.stopPropagation()
                                            setMenuSoundId(undefined)
                                        }} anchorEl={menuAnchro} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}>

                                            <DropdownMenu
                                                menuItems={[
                                                    soundPackage.demo_file_url ? {
                                                        text: 'Play Demo',
                                                        onClick: () => {
                                                            togglePlay(soundPackage, playerContext)
                                                        }
                                                    } : undefined,
                                                    soundPackage.demo_file_url_2 ? {
                                                        text: 'Play Demo 2',
                                                        onClick: () => {
                                                            togglePlay(soundPackage, playerContext, 2)
                                                        }
                                                    } : undefined,
                                                    {
                                                        text: soundPackage.is_favorited ? 'Unlike' : 'Like',
                                                        onClick: () => {
                                                            if (soundPackage.__typename == 'Curated') {
                                                                props.onCuratedLikeToggled && props.onCuratedLikeToggled(soundPackage)
                                                            } else {
                                                                props.onSoundPackageLikeToggled && props.onSoundPackageLikeToggled(soundPackage)
                                                            }
                                                        }
                                                    },
                                                ]}
                                            />
                                        </Popover>
                                    </li>
                                }}</PlayerContextConsumer>
                            )
                        }}</CuratedPackageWrapper>
                    })}
                </ul>
                {props.loading && <Loader centered />}
            </div>
        </div>
    )
}

export default Trending;