import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { Drive } from "../models/drive";
export class DriveAPI {

    public static getDrives(): Promise<Drive[]> {
        let apiRequest = new AuthenticatedRequest<Drive>('/drives', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static getDrive(id: string): Promise<Drive> {
        let apiRequest = new AuthenticatedRequest<Drive>('/drive/' + id, HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static deleteDrive(id: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest<void>('/drive/' + id, HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static createDrive(name: string): Promise<Drive> {
        let apiRequest = new AuthenticatedRequest<Drive>('/drives', HTTPMethod.POST, APIVersion.v1_0, undefined, { name });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static addToDrive(drive_id: string, sound_id: string): Promise<Drive> {
        let apiRequest = new AuthenticatedRequest<Drive>(`/drive/${drive_id}/${sound_id}`, HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static removeFromDrive(drive_id: string, sound_id: string): Promise<Drive> {
        let apiRequest = new AuthenticatedRequest<Drive>(`/drive/${drive_id}/${sound_id}`, HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }
}