import React, { useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import { ContestPrize } from '../../api/models/contest'
import { useWindowSize } from 'bpm-sounds-generic'

interface PrizeCards {
    cards: ContestPrize[] | undefined;
    viewButton?: boolean;
}

const PrizeCards = (props: PrizeCards) => {
    const prizeCards = useRef<HTMLDivElement>(null)
    // const bottomMargin = 20
    const totalCards = props.cards && props.cards.length
    const defaultCards = 3
    const duration = totalCards && 100 * totalCards

    const [expanded, setExpanded] = useState(false)
    const [willCollapse, setWillCollapse] = useState(false)
    const [height, setHeight] = useState<number>();
    const [initialHeight, setInitialHeight] = useState<number>();
    const { width } = useWindowSize()

    React.useEffect(() => {
        if (willCollapse) {
            setHeight(initialHeight)
            setTimeout(() => {
                setExpanded(false)
                setWillCollapse(false)
            }, duration)
        }
    }, [willCollapse])

    React.useEffect(() => {
        if (!willCollapse) {
            setHeight(prizeCards.current?.clientHeight)
        }
    })

    React.useEffect(() => {
        if (!expanded) {
            setInitialHeight(prizeCards.current?.clientHeight)
        }
    }, [width])


    return (
        !props.cards ? null :
            <div className="PrizeCards">
                <AnimateHeight
                    duration={duration}
                    height={height}
                >
                    <div ref={prizeCards}>
                        {
                            props.cards.slice(0, expanded ? undefined : defaultCards).map(card => {
                                return (
                                    <div className="prize-card" key={card.id}>
                                        <div className="img-container" style={{ background: `url(${card.image_url})`, backgroundSize: 'cover', backgroundPosition: 'center' }} onClick={() => window.open(card.link)}>
                                        </div>
                                        <div className="text-wrapper">
                                            <div className="title">{card.title}</div>
                                            <div className="subtext" dangerouslySetInnerHTML={{ __html: card.description }}></div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </AnimateHeight>
                {
                    totalCards && totalCards <= defaultCards ? null :
                        <div className="view-more" onClick={() => {
                            if (expanded) setWillCollapse(true)
                            else setExpanded(true)
                        }}>
                            {!expanded ? 'View More' : 'View Less'}
                        </div>
                }
            </div>
    )
}

export default PrizeCards 