import { AuthenticatedRequest, HTTPMethod, APIVersion, Session } from "../requests/request";
import { Sound } from "../models";
import { PagingInfo, PagingInfoResponse, SoundPackageQueryAttributes, SoundDisplayAttributes } from "./types";

export class SoundAPI {

    public static getSounds(query: SoundPackageQueryAttributes & SoundDisplayAttributes, paging: PagingInfo, token?: string): Promise<{ data: Sound[], pagination: PagingInfoResponse, token?: string }> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sounds', HTTPMethod.GET, APIVersion.v1_0, { ...query, ...paging });

        return apiRequest.send().then((response) => {
            return { ...response as { data: Sound[], pagination: PagingInfoResponse }, token }
        });
    }

    public static getSound(id: string): Promise<Sound> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sound/' + id, HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static getTrendingSounds(query: SoundPackageQueryAttributes, paging: PagingInfo, token?: string): Promise<{ data: Sound[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sounds/trending', HTTPMethod.GET, APIVersion.v1_0, { ...paging, ...query });

        return apiRequest.send().then((response) => {
            return { ...response as { data: Sound[], pagination: PagingInfoResponse }, token }
        });
    }

    public static getFeaturedSounds(query: SoundPackageQueryAttributes, paging: PagingInfo, token?: string): Promise<{ data: Sound[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sounds/featured', HTTPMethod.GET, APIVersion.v1_0, { ...paging, ...query });

        return apiRequest.send().then((response) => {
            return { ...response as { data: Sound[], pagination: PagingInfoResponse }, token }
        });
    }

    public static getLikedSounds(query: SoundPackageQueryAttributes, paging: PagingInfo, token?: string): Promise<{ data: Sound[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sounds/liked', HTTPMethod.GET, APIVersion.v1_0, { ...paging, ...query });

        return apiRequest.send().then((response) => {
            return { ...response as { data: Sound[], pagination: PagingInfoResponse }, token }
        });
    }

    public static getDownloadedSounds(query: SoundPackageQueryAttributes, paging: PagingInfo, token?: string): Promise<{ data: Sound[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sounds/downloaded', HTTPMethod.GET, APIVersion.v1_0, { ...paging, ...query });

        return apiRequest.send().then((response) => {
            return { ...response as { data: Sound[], pagination: PagingInfoResponse }, token }
        });
    }

    public static getSimilarSounds(sound: Sound, limit = 5): Promise<{ data: Sound[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sound/' + sound.id + '/similar', HTTPMethod.GET, APIVersion.v1_0, { limit });

        return apiRequest.send().then((response) => {
            return { ...response as { data: Sound[], pagination: PagingInfoResponse } }
        });
    }

    public static likeSound(sound: { id: string }): Promise<Sound> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sounds/' + sound.id + '/favorite', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static unLikeSound(sound: { id: string }): Promise<Sound> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sounds/' + sound.id + '/favorite', HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static downloadSound(sound: { id: string }, location: string): Promise<{ url: string }> {
        let apiRequest = new AuthenticatedRequest<{ url: string }>('/sounds/' + sound.id + '/download', HTTPMethod.GET, APIVersion.v1_0, { location });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static reportSound(sound: { id: string }): Promise<void> {
        let apiRequest = new AuthenticatedRequest<{ url: string }>('/sound/' + sound.id + '/report', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then(() => {
            return void 0;
        });
    }

    public static downloadMultiSoundPreview(sound_ids: string[]): Promise<{
        required: {
            total: number
            promo: number
            regular: number
        }, allowed: boolean, trial_applicable: boolean
    }> {
        let apiRequest = new AuthenticatedRequest<{ required: number, allowed: boolean }>('/sounds/downloadMulti-preview', HTTPMethod.GET, APIVersion.v1_0, { sound_ids });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static downloadMultiSound(sound_ids: string[], location: string): Promise<{ url: string }> {
        let apiRequest = new AuthenticatedRequest<{ url: string }>('/sounds/downloadMulti', HTTPMethod.GET, APIVersion.v1_0, { sound_ids, location });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static streamSound(sound: { id: string }, location: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest('/sounds/' + sound.id + '/stream', HTTPMethod.GET, APIVersion.v1_0, { location });
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static setStreamedDuration(sound: { id: string }, duration: number, requestType?: 'beacon'): Promise<void> {
        if (requestType === 'beacon') {
            navigator.sendBeacon(`${Session.getAPIURL()}/${APIVersion.v1_0}/sounds/${sound.id}/stream/duration`, JSON.stringify({ duration }))
            return Promise.resolve()
        }

        else {
            let apiRequest = new AuthenticatedRequest('/sounds/' + sound.id + '/stream/duration', HTTPMethod.PUT, APIVersion.v1_0, undefined,
                { duration });
            return apiRequest.send().then((response) => {
                return response.data
            });
        }
    }
}


