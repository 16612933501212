import { HTTPMethod, APIVersion, AdminRequest, AdminFileRequest } from "../requests/request";
import { Sound, SoundPackage } from "../models";
import { SoundAPI } from "./sound";
import { Tag } from "../models/tag";

type AllStringAndPartial<T> = {
    [P in keyof T]?: string;
};
export class AdminAPI {

    public static uploadSound(file: File, packgeId: string): Promise<{ url: string }> {
        let apiRequest = new AdminFileRequest<{}>('/sound/upload/' + packgeId, HTTPMethod.POST, APIVersion.v1_0, undefined, { file });
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static updateSoundPackage(id: string, update: Partial<SoundPackage>): Promise<SoundPackage> {
        let apiRequest = new AdminRequest('/soundpackage/' + id, HTTPMethod.PUT, APIVersion.v1_0, undefined, update);
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static async updateSound(id: string, update: AllStringAndPartial<Sound> & { file?: File }): Promise<Sound> {
        if (update.file && update.sound_package_id) {
            await AdminAPI.uploadSound(update.file, update.sound_package_id).then(link => {
                update['file_url'] = { mp3: link.url } as any
                delete update.file
                delete update.sound_package_id
            })
        }
        let apiRequest = new AdminRequest('/sound/' + id, HTTPMethod.PUT, APIVersion.v1_0, undefined, update);
        return apiRequest.send().then((response) => {
            return SoundAPI.getSound(id)
        });
    }

    public static addSoundTags(id: string, tagIds: string[]): Promise<Sound> {
        let apiRequest = new AdminRequest('/sound/' + id + '/tags', HTTPMethod.POST, APIVersion.v1_0, undefined, { id: tagIds });
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static deleteSoundTag(id: string, tagId: string): Promise<Sound> {
        let apiRequest = new AdminRequest('/sound/' + id + '/tags/' + tagId, HTTPMethod.DELETE, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static getKeys(): Promise<string[]> {
        let apiRequest = new AdminRequest('/keys/names', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static getTags(): Promise<TagCategories> {
        let apiRequest = new AdminRequest('/tagcategories-full', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static getCuratedCategories(): Promise<{ id: string, name: string }[]> {
        let apiRequest = new AdminRequest('/curatedcategory', HTTPMethod.GET, APIVersion.v1_0);
        return apiRequest.send().then((response) => {
            return response.data.data
        });
    }

    public static uploadCuratedArtwork(file: File): Promise<{ url: string }> {
        let apiRequest = new AdminFileRequest('/curated/image/temp', HTTPMethod.POST, APIVersion.v1_0, undefined, { image: file });
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static uploadCuratedDemo(file: File): Promise<{ url: string }> {
        let apiRequest = new AdminFileRequest('/curated/mp3/temp', HTTPMethod.POST, APIVersion.v1_0, undefined, { image: file });
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static createCuratedSet(set: CreateCurated): Promise<CreateCurated & { id: string }> {
        let apiRequest = new AdminRequest('/curated', HTTPMethod.POST, APIVersion.v1_0, undefined, set);
        return apiRequest.send().then((response) => {
            return response.data
        });
    }
}

export interface CreateCurated {
    artwork_url?: string
    demo_file_path?: string
    name?: string;
    slug?: string
    description?: string
    video_url?: string
    category_id?: string
    drive_id: string
}

export type TagCategories = {
    id: string
    name: string
    items: Tag[] | TagGroup[]
}[]

export type TagGroup = {
    id: string
    name: string
    items: Tag[]
}