import * as React from 'react'
import { RouteComponentProps } from 'react-router';
import { Divider, DefaultProps, SectionRenderer } from 'bpm-sounds-generic';
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';

const CuratedPage: React.FC<DefaultProps & RouteComponentProps> = (props) => {

    return <>
        <Divider small />
        <BannerRenderer location={'curated'} onItemClick={(type, id) => Analytics.trackClick(type, id)} />
        <SectionRenderer {...props} location='curated' />
    </>
}

export default CuratedPage
