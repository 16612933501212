import React, { useEffect, useState } from 'react'
import { PAUSE_RUBY_SVG, PLAY_RUBY_SVG } from "bpm-sounds-generic/assets"
import PrizeCards from '../../../components/contest/PrizeCards'
import DateCards from '../../../components/contest/DateCards'
import SubmissionCards from '../../../components/contest/SubmissionCards'
import CommentCards from '../../../components/contest/CommentCards'
import Header from '../../../components/contest/Header'
import { Redirect, RouteComponentProps, useHistory } from "react-router";
import { ContestAPI } from '../../../api/endpoints/contest'
import { Contest } from '../../../api/models/contest'
import { AddToHistory, DynamicPlaylist, FullPlaybackMediaInfo, PlaybackManager, PlayerState } from "bpm-sounds-generic"
import { PlayerContext, PlayerContextConsumer, Divider, Title, Loader } from 'bpm-sounds-generic'

interface ContestPageProps extends RouteComponentProps<{ slug: string }> { }

const ContestPage = (props: ContestPageProps) => {
    const [thisContest, setThisContest] = useState<Contest>()
    const [loading, setLoading] = useState(true);
    const [pastContests, setPastContests] = useState<Contest[]>()
    const [currentContests, setCurrentContests] = useState<Contest[]>()
    const history = useHistory()

    const internallist = React.useRef(new DynamicPlaylist([], {
        history_mode: AddToHistory.NONE
    }, 'contest', false));

    React.useEffect(() => {
        if (thisContest) {
            internallist.current.replace([{
                sound: PlaybackManager.contestToPlayableSound(thisContest),
                playlist: internallist.current
            } as FullPlaybackMediaInfo]);
        }
        PlaybackManager.getInstance().getContentManager().replaceLists(internallist.current);
    }, [thisContest])

    useEffect(() => {

        ContestAPI.getContest().then(contests => {
            setCurrentContests(contests.current)
            setPastContests(contests.past)
            if (!props.match.params.slug) {
                setThisContest(undefined)
            } else {
                const filtered = [...contests.current, ...contests.past].filter(contest => contest.slug.toLowerCase() === props.match.params.slug.toLowerCase())
                const this_contest = filtered[0]
                setThisContest(this_contest)
            }
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })

    }, [props.match.params.slug])

    const togglePlay = (playerContext: PlayerContext) => {
        let mediaSelected = false;

        if (playerContext.media && playerContext.state >= PlayerState.Loading && thisContest) {
            mediaSelected = PlaybackManager.isSoundEqualContest(thisContest, playerContext.media!.sound) !== false
        }

        if (mediaSelected) {
            PlaybackManager.getInstance().pause();
        } else {
            PlaybackManager.getInstance().getContentManager().replaceLists(internallist.current);
            PlaybackManager.getInstance().startTrack(internallist.current!.tracks()[0]);
        }
    }


    const renderContestsSection = (contestType: Contest[]) => {
        return (
            <div className={`contest-section ${!thisContest ? 'none-found' : ''}`}>
                {renderLine()}
                <Title
                    className="contest-section-title"
                    titleText={contestType === pastContests ? 'Past Contests' : 'Current Contests'}
                    fontSize={'24px'}
                    margins={'0 0 24px 0'}
                />
                <div className="artwork-container">
                    {contestType.map(contest => {
                        return (
                            <img className="artwork" key={contest.id} src={contest.artwork_url} alt="art" onClick={() => history.push(`/contest/${contest.slug}`)} />
                        )
                    })}
                </div>
            </div>
        )
    }


    const renderLine = () => {
        return (
            <>
                <Divider height={42} />
                <div className="line"></div>
                <Divider height={42} />
            </>
        )
    }


    const renderMainContent = (thisContest: Contest) => {
        return (
            <>
                <Header
                    contest={thisContest}
                />
                {thisContest.banner_image_url && <>
                    <Divider height={32} />
                    <img src={thisContest.banner_image_url} className="banner-image" />
                </>}
                <Divider height={32} />
                <DateCards
                    cards={[
                        {
                            text: 'Submission Start Date',
                            date: new Date(thisContest.start_date),
                        },
                        {
                            text: 'Submission Deadline',
                            date: new Date(thisContest.submission_deadline),
                        },
                        {
                            text: 'Winner Announced',
                            date: new Date(thisContest.announcement),
                        },
                    ]}
                />
                <div className="contest-title">{thisContest.subtitle}</div>
                <div className="contest-description" dangerouslySetInnerHTML={{ __html: thisContest.description }}>
                </div>
                <Divider height={42} />
                <div className="play-song">
                    <PlayerContextConsumer>{context => {
                        let mediaSelected = false;
                        if (context.media && context.state >= PlayerState.Loading) {
                            mediaSelected = PlaybackManager.isSoundEqualContest(thisContest, context.media!.sound) !== false
                        }
                        return <img className="play-btn" width={55} src={mediaSelected ? PAUSE_RUBY_SVG : PLAY_RUBY_SVG} alt="play" onClick={() => togglePlay(context)} />
                    }}</PlayerContextConsumer>

                    <div className="text-content">
                        <div className="title">
                            {thisContest.song_title}
                        </div>
                        <div className="flex">
                            <div className="artist">
                                {thisContest.song_artist}
                            </div>
                        </div>

                    </div>
                </div>

                {renderLine()}

                <Title
                    titleText="Prizes"
                    fontSize={'24px'}
                    margins={'0 0 24px 0'}
                />

                <PrizeCards cards={thisContest.ContestPrizes} />

                {renderLine()}

                <Title
                    titleText="Listen to Submissions"
                    fontSize={'24px'}
                    margins={'0 0 24px 0'}
                />
                <SubmissionCards contest={thisContest} />

                {renderLine()}

                <Title
                    titleText="Comments"
                    fontSize={'24px'}
                    margins={'0 0 24px 0'}
                />
                <CommentCards
                    contestId={thisContest.id}
                />
                {currentContests && currentContests.length > 1 ? renderContestsSection(currentContests) : null}
                {pastContests && pastContests.length > 1 ? renderContestsSection(pastContests) : null}
            </>

        )
    }

    if (loading) {
        return <Loader />
    }
    // ContestPage Return
    if (currentContests?.length == 1 && !props.match.params.slug) {
        return <Redirect to={'/contest/' + currentContests[0].slug} />
    }
    return (
        <div className="contest-background">
            <div className="Contest">
                {thisContest
                    ? renderMainContent(thisContest)
                    : <>
                        {props.match.params.slug && <div className="no-contest">{`Contest "${props.match.params.slug}" not found`}</div>}
                        {currentContests && currentContests.length > (props.match.params.slug ? 1 : 0) ? renderContestsSection(currentContests) : null}
                        {pastContests && pastContests.length > 0 ? renderContestsSection(pastContests) : null}
                    </>
                }
            </div>
        </div>
    )
}

export default ContestPage
