import React, { useEffect, useState } from 'react'
import PrizeCards from '../../../components/contest/PrizeCards'
import DateCards from '../../../components/contest/DateCards'
import CommentCards from '../../../components/giveaway/CommentCards'
import Header from '../../../components/giveaway/Header'
import { Redirect, RouteComponentProps, useHistory } from "react-router";
import { GiveawayAPI } from '../../../api/endpoints/giveaway'
import { Giveaway } from '../../../api/models/giveaway'
import { Divider, Title, Loader } from 'bpm-sounds-generic'
import { PopupGiveawayEntry } from './PopupGiveawayEntry'

interface GiveawayPageProps extends RouteComponentProps<{ slug: string }> { }

const GiveawayPage = (props: GiveawayPageProps) => {
    const [thisGiveaway, setThisGiveaway] = useState<Giveaway>()
    const [loading, setLoading] = useState(true);
    const [pastGiveaways, setPastGiveaways] = useState<Giveaway[]>()
    const [currentGiveaways, setCurrentGiveaways] = useState<Giveaway[]>()
    const [enterPopup, setEnterPopup] = useState(false)
    const history = useHistory()

    useEffect(() => {
        GiveawayAPI.getGiveaways('create').then(contests => {
            setCurrentGiveaways(contests.current)
            setPastGiveaways(contests.past)
            const filtered = [...contests.current, ...contests.past].filter(contest => contest.slug.toLowerCase() === props.match.params.slug.toLowerCase())
            const this_contest = filtered[0]
            setThisGiveaway(this_contest)
            setLoading(false)
        }).catch(() => {
            setLoading(false)
        })
    }, [props.match.params.slug])


    const renderGiveawaysSection = (contestType: Giveaway[]) => {
        return (
            <div className={`contest-section ${!thisGiveaway ? 'none-found' : ''}`}>
                {renderLine()}
                <Title
                    className="contest-section-title"
                    titleText={contestType === pastGiveaways ? 'Past Giveaways' : 'Current Giveaways'}
                    fontSize={'24px'}
                    margins={'0 0 24px 0'}
                />
                <div className="artwork-container">
                    {contestType.map(contest => {
                        return (
                            <img className="artwork" key={contest.id} src={contest.artwork_url} alt="art" onClick={() => history.push(`/giveaway/${contest.slug}`)} />
                        )
                    })}
                </div>
            </div>
        )
    }


    const renderLine = () => {
        return (
            <>
                <Divider height={42} />
                <div className="line"></div>
                <Divider height={42} />
            </>
        )
    }


    const renderMainContent = (thisGiveaway: Giveaway) => {
        return (
            <>
                {thisGiveaway && enterPopup && <PopupGiveawayEntry giveaway={thisGiveaway} onClose={() => {
                    setEnterPopup(false)
                }} />}
                <Header
                    giveaway={thisGiveaway} onEntry={() => {
                        setEnterPopup(true)
                    }}
                />
                {thisGiveaway.banner_image_url && <>
                    <Divider height={32} />
                    <img src={thisGiveaway.banner_image_url} className="banner-image" />
                </>}
                <Divider height={32} />
                <DateCards
                    cards={[
                        {
                            text: 'Entry Start Date',
                            date: new Date(thisGiveaway.start_date),
                        },
                        {
                            text: 'Entry Deadline',
                            date: new Date(thisGiveaway.entry_deadline),
                        },
                        {
                            text: 'Winner Announced',
                            date: new Date(thisGiveaway.announcement),
                        },
                    ]}
                />
                <div className="contest-title">{thisGiveaway.subtitle}</div>
                <div className="contest-description" dangerouslySetInnerHTML={{ __html: thisGiveaway.description }}>
                </div>
                <Divider height={42} />

                {renderLine()}

                <Title
                    titleText="Prizes"
                    fontSize={'24px'}
                    margins={'0 0 24px 0'}
                />

                <PrizeCards cards={thisGiveaway.GiveawayPrizes} />

                {renderLine()}

                <Title
                    titleText="Comments"
                    fontSize={'24px'}
                    margins={'0 0 24px 0'}
                />
                <CommentCards
                    contestId={thisGiveaway.id}
                />
                {currentGiveaways && currentGiveaways.length > 1 ? renderGiveawaysSection(currentGiveaways) : null}
                {pastGiveaways && pastGiveaways.length > 1 ? renderGiveawaysSection(pastGiveaways) : null}
            </>

        )
    }

    if (loading) {
        return <Loader />
    }
    // GiveawayPage Return
    if (currentGiveaways?.length && !props.match.params.slug) {
        return <Redirect to={'/giveaway/' + currentGiveaways[0].slug} />
    }
    return (
        <div className="contest-background">
            <div className="Contest">
                {thisGiveaway
                    ? renderMainContent(thisGiveaway)
                    : <>
                        {props.match.params.slug && <div className="no-contest">{`Giveaway "${props.match.params.slug}" not found`}</div>}
                        {currentGiveaways && currentGiveaways.length > (props.match.params.slug ? 1 : 0) ? renderGiveawaysSection(currentGiveaways) : null}
                        {pastGiveaways && pastGiveaways.length > 0 ? renderGiveawaysSection(pastGiveaways) : null}
                    </>
                }
            </div>
        </div>
    )
}

export default GiveawayPage
