import * as React from 'react'
import { RouteComponentProps, withRouter, useHistory } from "react-router";
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { DefaultProps } from "bpm-sounds-generic";
import { ManagedTrending } from '../components/ManagedTrending';
import FeaturedPacks from '../components/FeaturedPacks';
import { PagingInfoResponse, PagingInfo } from '../api/endpoints/types';
import { SoundPackage, Label } from '../api';
import * as uuid from 'uuid'
import { LabelAPI } from '../api/endpoints/label';
import TrendingLabels from '../components/TrendingLabels';
import { MediaAPI } from 'bpm-client-api';
import { Divider, Live, ManagedAlbumRow, SectionRenderer, VirtualSection } from 'bpm-sounds-generic'
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';

const Browse: React.FC<RouteComponentProps & DefaultProps> = (props) => {
    const history = useHistory()
    const [limit, setLimit] = React.useState(20)
    const loadingToken = React.useRef<string>()
    const [loading, setLoading] = React.useState(true)
    const [packages, setPackages] = React.useState<SoundPackage[]>()
    const [labels, setLabels] = React.useState<Label[]>()
    const [isLive, setIsLive] = React.useState(false)

    React.useEffect(() => {
        MediaAPI.getLiveStatus().then(status => {
            setIsLive(status.status.create == 'Live')
        })
    }, [])

    React.useEffect(() => {
        loadingToken.current = uuid.v4()
        setLoading(true)
        SoundPackageAPI.getFeaturedSoundPackages({ limit: limit, skip: 0 }).then(({ data }) => {
            setPackages(data)
            setLoading(false)
        })
        LabelAPI.getTrendingLabels().then((data) => {
            setLabels(data)
        })
    }, [limit])

    const soundPackageTransform = (slug: string) => {
        return (paging: PagingInfo): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> => {
            return SoundPackageAPI.getSoundPackagesByGenre(slug)(paging).then((data) => {
                return { data: data.data.packs, pagination: data.pagination }
            })
        }
    }

    return (
        <>
            <Divider small />
            {isLive && <div className='banner' style={{ marginBottom: "80px" }}>
                <Live {...props} maxHeight={500} useLiveInline />
            </div>}
            {!isLive && <BannerRenderer location={'browse'} onItemClick={(type, id) => Analytics.trackClick(type, id)} />}
            <SectionRenderer {...props} location='browse' virtualSections={[
                <VirtualSection positionIdentifier='Newest Packs'>
                    <ManagedAlbumRow {...props} context={'newest_packs'} apiFunc={SoundPackageAPI.getNewestSoundPackages} title='Newest Packs' onViewAllClick={() => {
                        Analytics.trackClick('view_all', 'Newest Packs')
                        history.push('/new')
                    }} />
                    <Divider />
                </VirtualSection>,
                <VirtualSection positionIdentifier='Featured Packs'>
                    <FeaturedPacks {...props} soundPackages={packages} title='Featured Packs' viewAll={true} onViewAllClick={() => { history.push('/featured') }} />
                    <Divider />
                </VirtualSection>,
                <VirtualSection positionIdentifier='Trending Packs'>
                    <ManagedTrending {...props} apiFunc={SoundPackageAPI.getTrendingSoundPackages} />
                    <Divider />
                </VirtualSection>,
                <VirtualSection positionIdentifier='Hip Hop'>
                    <ManagedAlbumRow {...props} context={'hip_hop_packs'} apiFunc={soundPackageTransform('Hip Hop & R&B')} onViewAllClick={() => {
                        Analytics.trackClick('view_all', 'Hip Hop Packs')
                        history.push('/genre/Hip Hop & R&B')
                    }} title='Hip Hop & R&B' />
                    <Divider />
                </VirtualSection>,
                <VirtualSection positionIdentifier='EDM'>
                    <ManagedAlbumRow {...props} context={'edm_packs'} apiFunc={soundPackageTransform('EDM / Radio Pop')} title='EDM / Radio Pop' onViewAllClick={() => {
                        Analytics.trackClick('view_all', 'EDM Packs')
                        history.push('/genre/' + encodeURIComponent('EDM / Radio Pop'))
                    }} />
                    <Divider />
                </VirtualSection>,
                <VirtualSection positionIdentifier='Global'>
                    <ManagedAlbumRow {...props} context={'global_packs'} apiFunc={soundPackageTransform('Global')} title='Global' onViewAllClick={() => {
                        Analytics.trackClick('view_all', 'Global Packs')
                        history.push('/genre/' + encodeURIComponent('Global'))
                    }} />
                    <Divider />
                </VirtualSection>
            ]} />

            <BannerRenderer location={'browse-bottom'} onItemClick={(type, id) => Analytics.trackClick(type, id)} />
            <Divider />
            {
                labels ? <TrendingLabels {...props} labels={labels} title='Trending Labels' onViewAllClick={() => {
                    history.push('/labels')
                }} /> : null
            }
        </>
    )
}

export default withRouter(Browse)