import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/main.scss";
import register from "./registerServiceWorker";
import Bugsnag from '@bugsnag/js'
import { Client } from "@bugsnag/core";
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { Session } from 'bpm-client-api'
import { Environment } from "./env";
import { BrowserRouter } from "react-router-dom";
import { MetaAPI } from "api/endpoints/meta";
import { AdminAPI } from "api/endpoints/admin";
import { SoundPackageAPI } from "api/endpoints/soundpackage";
import { SoundAPI } from "api/endpoints/sound";
import { Analytics } from "manager/AnalyticsManager";
import { BPMSoundsGeneric } from "bpm-sounds-generic";
import { DriveManager } from "manager/drivemanager";
import * as h from 'history'
import { KeyboardManager } from "manager/KeyboardManager";
import { SectionAPI } from "api/endpoints/section";
import { setGiveawayCookie } from "util/giveaway";

Session.setAPIURL(Environment.GENERIC_API);

BPMSoundsGeneric.initialize({
    loadGenres: MetaAPI.getGenres,
    loadKeys: AdminAPI.getKeys,
    loadTags: MetaAPI.getTags,
    loadSimiliarSoundPackages: SoundPackageAPI.getSimilarSoundPackages,
    getTagCategories: AdminAPI.getTags,
    updateSoundPackage: AdminAPI.updateSoundPackage,
    updateSound: AdminAPI.updateSound,
    driveManager: DriveManager.instance(),
    getSound: SoundAPI.getSound,
    getBanner: MetaAPI.getBanner,
    getSections: SectionAPI.getSection,
    addSoundTags: AdminAPI.addSoundTags,
    deleteSoundTag: AdminAPI.deleteSoundTag,
    getSimilarSounds: SoundAPI.getSimilarSounds,
    onStream: (sound) => {
        return SoundAPI.streamSound(sound, window.location.pathname)
    },
    setStreamedDuration: SoundAPI.setStreamedDuration,
    disableCaching: process.env.REACT_APP_STAGE == 'local'
})


declare global {
    interface Window {
        createBPMBanner: (content: string, href: string, color: string, styleElem?: HTMLStyleElement) => void;
        bugsnagClient?: Client;
        fbq?(type: 'trackCustom' | 'track', event: string): void;
        dataLayer?: Array<any>
        ga?: {
            getAll?(): [{
                send: (...arg1: string[]) => void
                set: (...arg1: string[]) => void
            }]
        }
        serviceWorkerAvailable?: boolean
    }
}

let ErrorBoundary = class extends React.Component { public render() { return this.props.children } }
if (process.env.REACT_APP_STAGE != 'local') {
    const pack = require('../package.json');
    const bugsnagClient = Bugsnag.start({
        apiKey: 'c570177ebcd4abd913da66f9a8a13cf8', plugins: [new BugsnagPluginReact()],
        releaseStage: process.env.NODE_ENV,
        appVersion: pack.version
    })
    window.bugsnagClient = bugsnagClient
    ErrorBoundary = bugsnagClient.getPlugin('react')!.createErrorBoundary(React)! as React.ComponentClass
}

function injectHistoryFunctions(ref: BrowserRouter | null) {
    if (ref) {
        const history = (ref as any).history as h.History
        const originalPush = history.push.bind(history)
        history.push = (path: string, state?: any) => {
            if (typeof path === 'object') {
                return originalPush(path)
            }
            if (KeyboardManager.optionalInstance()?.isMetaKeyPressed) {
                window.open(path, '_blank')
            } else
                return originalPush(path, state)
        }
    }
}
ReactDOM.render(<ErrorBoundary>
    <BrowserRouter ref={(ref) => {
        injectHistoryFunctions(ref)
    }}>
        <App />
    </BrowserRouter>
</ErrorBoundary>, document.getElementById("root"));
register()
setGiveawayCookie()
Analytics.initialize()
document.cookie = 'smcx_0_last_shown_at=; Max-Age=-99999999;';
const originalCreateBPMBanner = window.createBPMBanner
window.createBPMBanner = function (a, b, c) {
    let moveBanner = document.createElement('style')
    moveBanner.textContent = `.HeaderBar {
        top: 60px;
    }
    .AccountHeader {
        margin-top: 60px;
    }
    .main-content, .Sidebar {
        padding-top: calc(68px + 60px) !important;
    }`
    originalCreateBPMBanner(a, b, c, moveBanner)
    document.head.appendChild(moveBanner)
}