import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { DefaultProps, Divider } from "bpm-sounds-generic";
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'


const MIDI: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({ type: 'midi' })

    return (
        <>
            <Divider small />
            <BannerRenderer location={'midi'} onItemClick={(type, id) => Analytics.trackClick(type, id)} />
            <CreateManagedSoundList hideFilterKeys={['type']} pagingMode='manual'
                {...props} title='MIDI Files' filter={filter} onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }}
                apiFunc={SoundAPI.getSounds} context={'midi'} />
        </>
    )
}


export default withRouter(MIDI)