import { Options, request } from "./native_request";
import MapUtils from "./jsonparser";
import { APIErrorType, NotEnoughCreditsError } from "../errors";
import { User } from "../models";
import { Environment } from "../../env";
import { PagingInfoResponse } from "../endpoints/types";
import { InvalidSubscriptionError } from "bpm-client-api";

/**
 * The currenly used api url
 * @type {string}
 */
let apiURL = Environment.API;
let adminAPIURL = Environment.ADMIN_API

/**
 * The currenly used user agent
 * @type {string}
 */
let userAgent = 'bpmcreateweb';

/**
 * The global handler for unauthorized handlers
 */
let unauthorizedHandler: ((error: APIError) => void) | undefined = undefined;

/**
 * The global handler for unauthorized handlers
 */
let userUpdatedHandler: ((user: User) => void) | undefined = undefined;


/**
 * The global handler for subscription errors
 */
let notEnoughCreditsErrorHandler: ((error: NotEnoughCreditsError, from: string) => void) | undefined = undefined;

/**
 * The global handler for subscription errors
 */
let invalidSubscriptionHandler: ((error: InvalidSubscriptionError, from: string) => void) | undefined = undefined;

/**
 * This encapsulates the current session information
 */
export class Session {
    private static cachedSession: { session_token: string };
    /**
     * This sets the global unauthorized handler
     */
    static setUnauthorizedHandler(handler: ((error: APIError) => void) | undefined) {
        unauthorizedHandler = handler;
    }

    static setUserUpdatedHandler(handler: ((user: User) => void) | undefined) {
        userUpdatedHandler = handler;
    }

    static getUserUpdatedHandler() {
        return userUpdatedHandler || ((user) => { })
    }

    /**
     * This returns the global unauthorized handler
     */
    static getUnauthorizedHandler(): ((error: APIError) => void) | undefined {
        return unauthorizedHandler;
    }

    /**
     * This sets the global subscription error handler
     */
    static setSubscriptionHandler(handler: ((error: InvalidSubscriptionError, from: string) => void) | undefined) {
        invalidSubscriptionHandler = handler;
    }

    /**
     * This returns the subscription error handler
     */
    static getInvalidSubscriptionHandler(): ((error: InvalidSubscriptionError, from: string) => void) | undefined {
        return invalidSubscriptionHandler;
    }

    /**
     * This sets the global subscription error handler
     */
    static setNotEnoughCreditsHandler(handler: ((error: NotEnoughCreditsError, from: string) => void) | undefined) {
        notEnoughCreditsErrorHandler = handler;
    }

    /**
     * This returns the subscription error handler
     */
    static getNotEnoughCreditsHandler(): ((error: NotEnoughCreditsError, from: string) => void) | undefined {
        return notEnoughCreditsErrorHandler;
    }


    /**
     * This sets the api url to use
     * @param {string} url
     */
    static setAdminAPIURL(url: string) {
        adminAPIURL = url;
    }

    /**
     * This returns the currently used api url
     * @returns {string}
     */
    static getAdminAPIURL(): string {
        return adminAPIURL;
    }

    /**
     * This sets the api url to use
     * @param {string} url
     */
    static setAPIURL(url: string) {
        apiURL = url;
    }

    /**
     * This returns the currently used api url
     * @returns {string}
     */
    static getAPIURL(): string {
        return apiURL;
    }

    /**
     * This sets the user agent to use
     * @param {string} url
     */
    static setUserAgent(agent: string) {
        userAgent = agent;
    }

    /**
     * This returns the currently used user agent
     * @returns {string}
     */
    static getUserAgent(): string {
        return userAgent;
    }

    /**
     * This retreives the current session data
     * @returns {string | undefined}
     */
    static getSession(): string | undefined {
        return this.cachedSession ? this.cachedSession.session_token : undefined;
    }

    /**
     * This sets the current session data
     * @param {any | string | undefined} session
     */
    static setSession(session: any | string | undefined) {
        this.cachedSession = typeof session === 'object' ? session : { 'session_token': session }
    }
}


export type RequestType<T> = Options;
export type APIError = {
    type: APIErrorType
    status: number
    message: string
    underlying?: Error
    missing_keys?: string[]
    invalid_keys?: string[]
}

export enum HTTPMethod {
    GET = "GET",
    PUT = "PUT",
    DELETE = "DELETE",
    POST = "POST",
}

export enum APIVersion {
    v1_0 = "v1",
    v1_1 = "v1.1",
    v3_0 = "v3.0"
}

export type APIResponse<T> = { data?: T | T[], meta?: { [key: string]: any }, pagination?: PagingInfoResponse, originalResponse: Response }

export interface FCRequest<T> {

    path: string;
    method: HTTPMethod;
    apiVersion: APIVersion;
    queryParameters?: { [key: string]: string };
    bodyParameters?: { [key: string]: any };

    serialize(): RequestType<T>
}

export class BasicRequest<T> implements FCRequest<T> {

    apiUrl: string = apiURL;
    path: string;
    method: HTTPMethod;
    apiVersion: APIVersion;
    queryParameters?: { [key: string]: string };
    bodyParameters?: { [key: string]: any };
    refresh_token?: string;
    preventNotEnoughCreditsHandler = false
    preventInvalidSubscriptionHandler = false
    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }) {
        this.path = path;
        this.method = method;
        this.apiVersion = apiVersion;
        this.queryParameters = queryParameters
        this.bodyParameters = bodyParameters;
    }

    serialize(): RequestType<T> {
        let apiRequest: Options = {
            url: this.apiUrl + '/' + this.apiVersion + this.path,
            json: true,
            method: this.method,
            headers: {},
            user_agent: userAgent
        };
        if (this.refresh_token) {
            apiRequest.headers = Object.assign({}, apiRequest.headers, { 'Authorization': 'Bearer ' + this.refresh_token })
        }
        if (this.queryParameters) {
            apiRequest.qs = this.queryParameters
        }
        if (this.bodyParameters) {
            apiRequest.body = this.bodyParameters;
        }
        return apiRequest
    }

    public send(returnType?: { new(): T }, from: string = 'unknown'): Promise<APIResponse<any>> {
        let promise = new Promise((resolve, reject: (reason?: any) => void) => {
            request(this.serialize(), (error?: Error, response?: Response, body?: any) => {
                if (error) {
                    reject(error);
                } else if (response && response.status >= 200 && response.status <= 299) {
                    if (body && body.data) {
                        if (!returnType) {
                            return resolve({
                                data: body.data,
                                pagination: body.pagination,
                                originalResponse: response
                            })
                        }
                        if (Array.isArray(body.data)) {
                            resolve({
                                data: body.data.map((body: any): T | undefined => {
                                    try {
                                        return this.parseReponse(returnType, body)
                                    } catch (error) {
                                        return undefined
                                    }
                                }).filter(Boolean),
                                pagination: body.pagination,
                                originalResponse: response
                            });
                        } else {
                            try {
                                resolve({
                                    data: this.parseReponse(returnType, body.data),
                                    pagination: body.pagination,
                                    originalResponse: response
                                });
                            } catch (error) {
                                reject({
                                    type: APIErrorType.UnknownError,
                                    status: 500,
                                    underlyingError: error,
                                    message: error.message || 'The response could not be parsed'
                                })
                            }
                        }
                    } else if (body && body.error) {
                        if ([APIErrorType.SessionNotFoundError, APIErrorType.MissingAuthorizationError].includes(body.error.type) && unauthorizedHandler) {
                            try {
                                unauthorizedHandler(body.error);
                            } catch (e) {
                            }
                        } else if (!this.preventNotEnoughCreditsHandler && [APIErrorType.NotEnoughCreditsError].includes(body.error.type) && notEnoughCreditsErrorHandler) {
                            try {
                                notEnoughCreditsErrorHandler(body.error, from);
                            } catch (e) {
                            }
                        } else if (!this.preventInvalidSubscriptionHandler && [APIErrorType.InvalidSubscriptionError].includes(body.error.type) && invalidSubscriptionHandler) {
                            try {
                                invalidSubscriptionHandler(body.error, from);
                            } catch (e) {
                            }
                        }
                        reject(body.error)
                    } else {
                        resolve({
                            data: null,
                            originalResponse: response
                        })
                    }
                } else {
                    if (body && body.error) {
                        if ([APIErrorType.SessionNotFoundError, APIErrorType.MissingAuthorizationError].includes(body.error.type) && unauthorizedHandler) {
                            try {
                                unauthorizedHandler(body.error);
                            } catch (e) {
                            }
                        } else if (!this.preventNotEnoughCreditsHandler && [APIErrorType.NotEnoughCreditsError].includes(body.error.type) && notEnoughCreditsErrorHandler) {
                            try {
                                notEnoughCreditsErrorHandler(body.error, from);
                            } catch (e) {
                            }
                        } else if (!this.preventInvalidSubscriptionHandler && [APIErrorType.InvalidSubscriptionError].includes(body.error.type) && invalidSubscriptionHandler) {
                            try {
                                invalidSubscriptionHandler(body.error, from);
                            } catch (e) {
                            }
                        }
                        reject(body.error)
                    } else {
                        reject({
                            type: APIErrorType.UnknownError,
                            status: 500,
                            message: 'The response could not be parsed'
                        })
                    }
                }
            })
        });
        return <Promise<APIResponse<T>>>promise.catch((error) => {
            if (error.type && error.message && error.status) {
                throw error
            } else {
                const error: any = new Error('message');
                error.type = APIErrorType.NetworkError;
                error.status = 999;
                error.message = 'An unknown error occurred / ' + this.path + ' / ' + this.method;
                error.underlying = error;
                throw error
            }
        });
    }

    private parseReponse(clazz: { new(): T }, response: any): T | undefined {
        return MapUtils.deserialize(clazz, response)
    }

}

export class AuthenticatedRequest<T> extends BasicRequest<T> {

    private authToken?: string;

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }) {
        super(path, method, apiVersion, queryParameters, bodyParameters);
        this.authToken = Session.getSession();
    }

    serialize(): RequestType<T> {
        let options = super.serialize();
        if (this.authToken && !this.refresh_token) {
            options.headers = Object.assign({}, options.headers, { 'Authorization': 'Bearer ' + this.authToken });
        }
        return options
    }
}

export class AdminRequest<T> extends AuthenticatedRequest<T> {

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }) {
        super('/admin' + path, method, apiVersion, queryParameters, bodyParameters);
        this.apiUrl = Session.getAdminAPIURL()
    }
}

export class AuthenticatedFileRequest<T> extends AuthenticatedRequest<T> {

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: string },
        bodyParameters?: { [key: string]: any }) {
        if (bodyParameters) {
            let body = new FormData();
            let data = bodyParameters;
            for (let key of Object.keys(data)) {
                if (data[key] instanceof File) {
                    body.append(key, data[key], (data[key] as File).name);
                } else {
                    body.append(key, data[key])
                }
            }
            bodyParameters = body;
        }
        super(path, method, apiVersion, queryParameters, bodyParameters);
    }

    serialize(): RequestType<T> {
        let options = super.serialize();
        options.json = false;
        return options
    }
}

export class AdminFileRequest<T> extends AuthenticatedFileRequest<T> {

    constructor(path: string,
        method: HTTPMethod,
        apiVersion: APIVersion = APIVersion.v1_0,
        queryParameters?: { [key: string]: any },
        bodyParameters?: { [key: string]: any }) {
        super('/admin' + path, method, apiVersion, queryParameters, bodyParameters);
        this.apiUrl = Session.getAdminAPIURL()
    }
}