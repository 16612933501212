import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { Tag } from "../models/tag";
import { SoundPackageQueryAttributes } from "./types";
import { Genre, Label } from "../models";
import { Banner } from "../models/banner";

export class MetaAPI {

    public static getTags(query: SoundPackageQueryAttributes & { category?: string, requireSoundCount?: boolean }, token?: string): Promise<{ data: Tag[], token?: string }> {
        let apiRequest = new AuthenticatedRequest<Tag>('/tags', HTTPMethod.GET, APIVersion.v1_0, query);

        return apiRequest.send().then((response) => {
            return { data: <Tag[]>response.data, token }
        });
    }

    public static getGenres(): Promise<Genre[]> {
        let apiRequest = new AuthenticatedRequest<Genre>('/genres', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return <Genre[]>response.data
        });
    }

    public static getLabels(): Promise<Label[]> {
        let apiRequest = new AuthenticatedRequest<Label>('/labels', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return <Label[]>response.data
        });
    }

    public static getBanner(location: string): Promise<Banner | null> {
        let apiRequest = new AuthenticatedRequest<Banner>('/banner', HTTPMethod.GET, APIVersion.v1_0, { location });

        return apiRequest.send().then((response) => {
            return <Banner>response.data
        });
    }

}