import * as React from "react";
import Trending, { TrendingProps } from "./Trending";
import { PagingInfo, PagingInfoResponse } from "../api/endpoints/types";
import { SoundPackage } from "../api";
import * as uuid from 'uuid'
import { errorToString } from "bpm-client-api";
import * as toastr from 'toastr'
interface ManagedTrendingProps extends TrendingProps {
    apiFunc: (paging: PagingInfo, token?: string) => Promise<{ data: SoundPackage[], pagination: PagingInfoResponse, token?: string }>
}
export function ManagedTrending(props: ManagedTrendingProps) {

    const [limit, setLimit] = React.useState(10)
    const loadingToken = React.useRef<string>()
    const [loading, setLoading] = React.useState(true)
    const [sounds, setSounds] = React.useState<{ data: SoundPackage[], paging: PagingInfoResponse }>()

    React.useEffect(() => {
        loadingToken.current = uuid.v4()
        setLoading(true)
        props.apiFunc({ limit: limit, skip: 0 }, loadingToken.current).then(({ data, pagination, token }) => {
            if (loadingToken.current == token || !token) {
                setSounds({ data, paging: pagination })
                setLoading(false)
            }
        }).catch(err => {
            errorToString(err).forEach(err => toastr.error(err))
        })
    }, [limit])

    return <Trending soundsPacks={sounds?.data} viewAll loading={loading} {...props} />
}