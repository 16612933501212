import React, { useRef, useState, useEffect } from "react";
import { FAV_SVG, FAV_FILLED_SVG, ELLIPSES_SVG, PAUSE_RUBY_SVG, PLAY_RUBY_SVG, CHEVRON_LEFT_SVG } from "bpm-sounds-generic/assets";
import { Loader } from 'bpm-sounds-generic';
import { useHistory } from "react-router";
import { DropdownMenu } from "bpm-sounds-generic";
import { Title } from "bpm-sounds-generic";
import { DefaultProps } from "bpm-sounds-generic";
import { CuratedPackageWrapper } from "bpm-sounds-generic"
import { Popover } from "@material-ui/core";
import { DynamicPlaylist, AddToHistory, FullPlaybackMediaInfo, PlaybackManager, PlayerState } from "bpm-sounds-generic"
import { PlayerContext, PlayerContextConsumer } from "bpm-sounds-generic"
import { CuratedPackage } from "../api/models/curated";
import { SoundPackage } from "../api";

export interface AlbumRowProps extends DefaultProps {
    title: string;
    viewAll?: boolean;
    onViewAllClick?: () => void;
    loading?: boolean;
    subtitle?: string; // description underneath
    emptyPlaceholder?: string;
    soundPackages?: SoundPackage[];
}

const FeaturedPacks = (props: AlbumRowProps) => {
    const [dropdownID, setDropdownID] = useState(null)
    const [viewAll, setViewAll] = useState(props.viewAll || false)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const history = useHistory()
    const [showButtonLeft, setShowButtonLeft] = useState(false);
    const [showButtonRight, setShowButtonRight] = useState(true);
    const [rightMargin, setRightMargin] = useState(0);

    const buttonLeft = useRef<HTMLDivElement>(null);
    const buttonRight = useRef<HTMLDivElement>(null);
    const albumsContainer = useRef<HTMLDivElement>(null);
    const albumAndTextContainer = useRef<HTMLDivElement>(null);

    const internallist = React.useRef(new DynamicPlaylist([], {
        history_mode: AddToHistory.ALL
    }, 'trending', false));

    React.useEffect(() => {
        internallist.current.replace((props.soundPackages || []).reduce((prev, pack) => {
            if (pack.demo_file_url) {
                prev.push({
                    sound: PlaybackManager.packageToPlayableSound(pack, 1),
                    playlist: internallist.current
                } as FullPlaybackMediaInfo);
            }
            if (pack.demo_file_url_2) {
                prev.push({
                    sound: PlaybackManager.packageToPlayableSound(pack, 2),
                    playlist: internallist.current
                } as FullPlaybackMediaInfo);
            }
            return prev
        }, [] as FullPlaybackMediaInfo[]));
        PlaybackManager.getInstance().getContentManager().replaceLists(internallist.current);
    }, [props.soundPackages])

    const togglePlay = (pack: CuratedPackage, playerContext: PlayerContext, demo: 1 | 2 = 1) => {
        let mediaSelected = false;

        if (playerContext.media && playerContext.state >= PlayerState.Loading) {
            mediaSelected = PlaybackManager.isSoundEqualSoundPackage(pack, playerContext.media!.sound) !== false
        }

        if (mediaSelected) {
            PlaybackManager.getInstance().pause();
        } else {
            PlaybackManager.getInstance().getContentManager().replaceLists(internallist.current);
            PlaybackManager.getInstance().startTrack(internallist.current.tracks().find(a => PlaybackManager.isSoundEqualSoundPackage(pack, a.sound) == demo)!);
        }
    }

    const handleDropdown = (id: any, event: React.MouseEvent<any>) => {
        event.stopPropagation()
        if (id === dropdownID) setDropdownID(null)
        else {
            setAnchorEl(event.currentTarget)
            setDropdownID(id)
        }
    }

    // SCROLLING BUTTONS LOGIC 
    const computeRightMargin = () => {
        if (
            albumsContainer.current &&
            albumsContainer.current.children.length &&
            albumAndTextContainer.current
        ) {
            const rightMarginAsArray = window
                .getComputedStyle(albumAndTextContainer.current)
                .getPropertyValue("margin-right")
                .split("");
            const rightMargin = parseInt(
                rightMarginAsArray[0] + rightMarginAsArray[1]
            );
            return rightMargin;
        }
        return 0
    };

    const easeInOutQuad = (
        currentTime: number,
        startValue: number,
        offset: number,
        duration: number
    ) => {
        //https://stackoverflow.com/questions/52747018/horizontal-scrolling-using-buttons-in-reactjs
        currentTime /= duration / 2;
        if (currentTime < 1) {
            return (offset / 2) * currentTime * currentTime + startValue;
        }
        currentTime--;
        return (
            (-offset / 2) * (currentTime * (currentTime - 2) - 1) + startValue
        );
    };

    const getNewOffset = (start: number, offset: number): number => {
        let rest = start % offset;
        return rest < Math.abs(offset) / 2
            ? offset - rest
            : offset - rest + Math.abs(offset);
    };

    const updateOffset = () => {
        if (
            albumsContainer.current &&
            buttonLeft.current &&
            buttonRight.current
        ) {
            if (albumsContainer.current.scrollLeft <= 10 && showButtonLeft) {    // 0...10 => hide, 11, 12.. => show it
                setShowButtonLeft(false)
            } else if (albumsContainer.current.scrollLeft > 10 && !showButtonLeft) {
                setShowButtonLeft(true)
            }

            const maxScrollLeft = (albumsContainer.current.scrollWidth - albumsContainer.current.clientWidth) - rightMargin

            if (albumsContainer.current.scrollLeft < maxScrollLeft - 10) {
                setShowButtonRight(true)
            }
            else if (albumsContainer.current.scrollLeft >= maxScrollLeft) {
                setShowButtonRight(false)
            }
        }
    };

    var scrollRequestCode = React.useRef(0);
    useEffect(() => {
        setRightMargin(computeRightMargin())
    }, [props.soundPackages])

    const scrollLeft = (
        element: HTMLDivElement,
        offset: number,
        duration: number
    ) => {
        var start = element.scrollLeft,
            currentTime = 0,
            increment = 20;
        offset = getNewOffset(start, offset);
        let _requestCode = ++scrollRequestCode.current;

        var animateScroll = function () {
            if (_requestCode !== scrollRequestCode.current) {
                return;
            }
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, offset, duration);
            element.scrollLeft = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };

    const renderPackage = (soundPackage: CuratedPackage) => {
        return (
            <div
                className="album-and-text-container"
                ref={albumAndTextContainer} onClick={() => {
                    if (soundPackage.__typename == 'Curated') {
                        props.onCuratedClick(soundPackage)
                    } else
                        props.onSoundPackageClick(soundPackage)
                }}>
                <div className="cover-container">
                    <PlayerContextConsumer>{(context) => {
                        let mediaSelected = false;

                        if (context.media && context.state >= PlayerState.Loading) {
                            mediaSelected = PlaybackManager.isSoundEqualSoundPackage(soundPackage, context.media!.sound) !== false
                        }
                        return <>
                            <div className="clickables-container" style={dropdownID === soundPackage.id ? { visibility: 'unset' } : {}
                            }>
                                <img className={`heart ${soundPackage.is_favorited ? 'no-fill' : ''}`} src={soundPackage.is_favorited ? FAV_FILLED_SVG : FAV_SVG} alt="" onClick={(e) => {
                                    e.stopPropagation()
                                    if (soundPackage.__typename == 'Curated') {
                                        props.onCuratedLikeToggled && props.onCuratedLikeToggled(soundPackage)
                                    } else {
                                        props.onSoundPackageLikeToggled && props.onSoundPackageLikeToggled(soundPackage)
                                    }
                                }} />
                                <img className="ellipses no-select" src={ELLIPSES_SVG} onClick={(event) => {
                                    handleDropdown(soundPackage.id, event)
                                }} id={soundPackage.id} alt="ellipses" />

                                <div className='Dropdown' style={dropdownID === soundPackage.id ? {} : { visibility: 'hidden' }}>
                                    <Popover open={dropdownID === soundPackage.id} onClick={(e) => {
                                        e.stopPropagation()
                                        setDropdownID(null)
                                    }} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}>
                                        <DropdownMenu
                                            menuItems={[
                                                soundPackage.demo_file_url ? {
                                                    text: 'Play Demo',
                                                    onClick: () => {
                                                        togglePlay(soundPackage, context)
                                                    }
                                                } : undefined,
                                                soundPackage.demo_file_url_2 ? {
                                                    text: 'Play Demo 2',
                                                    onClick: () => {
                                                        togglePlay(soundPackage, context, 2)
                                                    }
                                                } : undefined,
                                                {
                                                    text: soundPackage.is_favorited ? 'Unlike' : 'Like',
                                                    onClick: () => {
                                                        if (soundPackage.__typename == 'Curated') {
                                                            props.onCuratedLikeToggled && props.onCuratedLikeToggled(soundPackage)
                                                        } else {
                                                            props.onSoundPackageLikeToggled && props.onSoundPackageLikeToggled(soundPackage)
                                                        }
                                                    }
                                                },
                                                {
                                                    text: 'Find Similar',
                                                    onClick: () => {
                                                        history.push(`/similarpacks/${soundPackage.id}`)
                                                    }
                                                }
                                            ]}
                                        />
                                    </Popover>
                                </div>
                            </div>
                            {soundPackage.demo_file_url && <img
                                className="play-button no-select"
                                src={mediaSelected ? PAUSE_RUBY_SVG : PLAY_RUBY_SVG}
                                alt="play/pause"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    togglePlay(soundPackage, context)
                                }}>
                            </img>}


                            <img
                                className="cover"
                                src={soundPackage.artwork_url}
                                alt="album cover"
                            />
                        </>
                    }}</PlayerContextConsumer>
                </div>

                <div className="text-container">
                    <div className="title">
                        {soundPackage.name}
                    </div>
                    <div className="genre" onClick={() => {
                        if (soundPackage.Genre) {
                            history.push('genre/' + encodeURIComponent(soundPackage.Genre.name))
                        }
                    }}>
                        {soundPackage.Genre ? soundPackage.Genre.name : ''}
                    </div>
                    <div className="label">
                        {soundPackage.Label ? soundPackage.Label.name : ''}
                    </div>
                </div>
            </div>

        );
    }

    return (
        <div className="FeaturedPacks">
            <div className="header">
                <div className="left-container">
                    <Title titleText={props.title} viewAll={'all'} onClickViewAll={() => {
                        if (props.onViewAllClick) {
                            return props.onViewAllClick()
                        }
                        viewAll ? setViewAll(false) : setViewAll(true)
                    }} />
                </div>
                {
                    props.soundPackages && props.soundPackages.length <= 7 ? null :
                        <div className="right-container">
                            <span
                                className={`left-button ${showButtonLeft ? 'background-crimson-bright cursor-pointer' : 'album-button-disabled'}`}
                                // style={viewAll ? { opacity: '0' } : undefined}
                                ref={buttonLeft}
                                onClick={() => {
                                    albumsContainer.current && albumsContainer.current.children.length &&
                                        scrollLeft(
                                            albumsContainer.current,
                                            -(albumsContainer.current.children[0] as HTMLDivElement).offsetWidth - rightMargin,
                                            500
                                        );
                                }}
                            >
                                <img className="no-select" src={CHEVRON_LEFT_SVG} alt="" />
                            </span>
                            <span
                                className={`right-button ${showButtonRight ? 'background-crimson-bright cursor-pointer' : 'album-button-disabled'}`}
                                ref={buttonRight}
                                // style={viewAll ? { opacity: '0' } : undefined}
                                onClick={() => {
                                    albumsContainer.current && albumsContainer.current.children.length &&
                                        scrollLeft(
                                            albumsContainer.current,
                                            (albumsContainer.current.children[0] as HTMLDivElement).offsetWidth + rightMargin,
                                            500
                                        );
                                }}>
                                <img className="no-select" src={CHEVRON_LEFT_SVG} alt="" />
                            </span>
                        </div>
                }
            </div>
            {/* ALBUMS */}

            {
                (() => {
                    const { soundPackages, emptyPlaceholder } = props
                    if (soundPackages) {
                        if (soundPackages.length === 0 && !emptyPlaceholder) {
                            return <div className="no-packs">No Packs Available Yet</div>
                        }
                        else if (soundPackages.length === 0 && emptyPlaceholder) {
                            return <div className="no-packs">{emptyPlaceholder}</div>
                        }
                        else {
                            return (
                                <div
                                    className="content albums-container no-scrollbar"
                                    ref={albumsContainer}
                                    onScroll={() => updateOffset()}
                                    style={viewAll ? { flexWrap: 'wrap' } : { flexWrap: 'unset' }}
                                >
                                    {!props.loading && props.soundPackages != undefined && props.soundPackages.map((soundPackage, index) => {
                                        return (
                                            <CuratedPackageWrapper key={soundPackage.id} curated={soundPackage}>{soundPackage => {
                                                return renderPackage(soundPackage)
                                            }}
                                            </CuratedPackageWrapper>
                                        )
                                    })}
                                    {props.loading && <Loader centered />}
                                </div>
                            )
                        }
                    }
                })()
            }
        </div >
    );
};

export default FeaturedPacks;
