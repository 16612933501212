import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { DefaultProps, Divider, ManagedAlbumRow } from "bpm-sounds-generic";
import { LabelAPI } from '../api/endpoints/label';
import { PagingInfo, PagingInfoResponse } from '../api/endpoints/types';
import { SoundPackage } from '../api';
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';

interface ManagedSoundListPropsGeneric extends DefaultProps {
    defaultLimit?: number
}

const LabelPacks: React.FC<RouteComponentProps<{ name: string }> & ManagedSoundListPropsGeneric> = (props) => {

    // paging 
    const [forceToggle, setForceToggle] = React.useState(false)
    const [limit, setLimit] = React.useState(props.defaultLimit || 20)
    const [page, setPage] = React.useState(1)

    const soundPackageTransform = (slug: string) => {
        return (paging: PagingInfo): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> => {
            return LabelAPI.getNewestPacksForLabelName(slug, paging).then((data) => {
                return { data: data.data, pagination: data.pagination }
            })
        }
    }

    return (
        <>
            <Divider small />
            <BannerRenderer location={'label'} onItemClick={(type, id) => Analytics.trackClick(type, id)} />
            <ManagedAlbumRow {...props} context={'newest_packs'} defaultExpanded={true}
                title={props.match.params.name} apiFunc={soundPackageTransform(props.match.params.name)}
                onLoadMore={(limitOrPage, force, isPaging) => {
                    if (!isPaging) {
                        if (limitOrPage == limit && force) {
                            setForceToggle(!forceToggle)
                        } else {
                            setLimit(limitOrPage)
                        }
                    } else {
                        setPage(limitOrPage)
                    }
                }}
            />
            <Divider />
        </>
    )
}

export default withRouter(LabelPacks)