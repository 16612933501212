import React, { useContext, useEffect } from 'react'
import { UserContext } from 'bpm-sounds-generic'
import { Button } from 'bpm-sounds-generic'
import { ContestAPI } from '../../api/endpoints/contest'
import { ContestComment } from '../../api/models/contest'
import { USER_PLACEHOLDER } from 'bpm-sounds-generic/assets'

interface CommentBoxProps {
    contestId: string;
    editingComment?: ContestComment
    onCommentSubmit: (comment: ContestComment) => void;
}

const CommentBox = (props: CommentBoxProps) => {
    const user = useContext(UserContext)
    const [comment, setComment] = React.useState<string>(props.editingComment?.comment || '')

    useEffect(() => {
        if (props.editingComment) {
            setComment(props.editingComment.comment)
        }
    }, [props.editingComment])

    return (
        <div className="CommentBox">
            <div className="flex">
                {user.profile_image_thumbnail_url ? <img className="user-image" src={user.profile_image_thumbnail_url} /> : <img className="user-image" src={USER_PLACEHOLDER} />}
                <textarea name="" value={comment} onChange={(e) => setComment(e.target.value)}></textarea>
            </div>
            <Button
                buttonText={props.editingComment ? 'Edit Comment' : 'Post Comment'} textColor={'#fff'} backgroundColor={'var(--primary)'}
                hoverEffect={true}
                onClick={() => {
                    if (comment && comment.trim()) {
                        if (props.editingComment) {
                            ContestAPI.editComment(props.editingComment.id, comment).then((response) => {
                                setComment('')
                                props.onCommentSubmit(response)
                            }).catch((err) => {
                                console.log(err)
                            })
                        } else {
                            ContestAPI.commentContest(props.contestId, comment).then((response) => {
                                setComment('')
                                props.onCommentSubmit(response)
                            }).catch((err) => {
                                console.log(err)
                            })
                        }
                    }
                }}
            />
        </div>
    )
}

export default CommentBox