import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { Loader } from 'bpm-sounds-generic';
import { SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { Tag } from '../api/models/tag';
import { SoundAPI } from '../api/endpoints/sound';
import { CuratedPackage } from '../api/models/curated';
import { CuratedAPI } from '../api/endpoints/curated';
import { AlbumHeader, Divider, onTagClick, DefaultProps } from 'bpm-sounds-generic';
import { Analytics } from 'manager/AnalyticsManager';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'


const IndividualCurated: React.FC<RouteComponentProps<{ id: string }> & DefaultProps> = (props) => {

    const [pack, setPack] = React.useState<CuratedPackage>()
    const [allTags, setAllTags] = React.useState<Tag[]>([])
    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({ curated_id: props.match.params.id })

    React.useEffect(() => {
        CuratedAPI.getCuratedById(props.match.params.id).then(({ pack, topTags }) => {
            setPack(pack)
            setAllTags(topTags)
            Analytics.trackView('curated-set', pack.id)
        })
        setFilter((filter) => {
            return { ...filter, curated_id: props.match.params.id }
        })
    }, [props.match.params.id])

    if (!pack) {
        return <Loader centered style={{ marginTop: 20 }} />
    }

    return (
        <>
            <AlbumHeader {...props} pack={pack} tags={allTags} selectedTags={filter.tags}
                onTagClick={(tag) => {
                    onTagClick(tag, filter, setFilter)
                }} onSoundPackageLikeToggled={props.onSoundPackageLikeToggled} onCuratedLikeToggled={props.onCuratedLikeToggled} />
            <Divider />
            <CreateManagedSoundList {...props} apiFunc={SoundAPI.getSounds} filter={filter} onFilterChange={(newFilter) => {
                setFilter((filter) => {
                    return { ...filter, ...newFilter }
                })
            }} context={'curated'} hideFilterKeys={['curated_id']} />
        </>
    )
}

export default withRouter(IndividualCurated)

