import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import LabelsComponent from '../components/LabelsComponent'
import { MetaAPI } from '../api/endpoints/meta';
import * as toastr from 'toastr'
import { errorToString, Label } from '../api';
import { Loader, Title, Divider } from 'bpm-sounds-generic';
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';
import { MAGNIFY_SVG, PLUS_SVG } from 'bpm-sounds-generic/assets';

const Labels: React.FC<RouteComponentProps> = () => {
    const [search, setSearch] = useState<string>();
    const [labels, setLabels] = useState<Label[]>()

    useEffect(() => {
        MetaAPI.getLabels().then(setLabels).catch(err => {
            toastr.error(errorToString(err).join('\n'))
        })
    }, [])

    if (!labels) {
        return <Loader centered style={{ marginTop: '10%' }}
            size={'4rem'}
        />
    }

    // Sort by pack amount, but keep BPMCreate as #1
    const sortLabels = (labels: Label[]) => {
        let sorted = labels.sort((label1: Label, label2: Label) => label2.soundpackage_count! - label1.soundpackage_count!)
        const BPMCreate = sorted.filter(label => label.name === "BPM Create");
        const newArray = sorted.filter(label => label.name !== "BPM Create");
        return [...BPMCreate, ...newArray]
    }

    return (
        <div className="LabelsPage">
            <Divider small />
            <BannerRenderer location={'labels'} onItemClick={() => Analytics.trackClick} />
            <Divider className="labels-divider-second" />

            <div className="header">
                <div className="title">
                    <Title
                        titleText={'Labels'}
                    />
                </div>
                <div className='search-labels'>
                    <img className="magnify-icon no-select" src={MAGNIFY_SVG} alt="" />
                    <input
                        className="search-bar"
                        name="input"
                        type="search"
                        onChange={e => {
                            setSearch(e.currentTarget.value.toLowerCase());
                        }}
                        placeholder="Search Labels"
                        value={search || ''}
                    />

                    <img className={`cancel-grey ${!search ? 'hidden' : ''}`} src={PLUS_SVG} onClick={() => {
                        setSearch(undefined)
                    }} alt="cancel" />
                </div>
            </div>
            <Divider height={'3.4rem'} />

            <LabelsComponent
                search={search}
                labels={sortLabels(labels)}
            />
        </div>
    )
}

export default withRouter(Labels)

