import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { PagingInfo, PagingInfoResponse } from "./types";
import { CuratedCategory } from "../models/curated_category";
import { CuratedPackage } from "../models/curated";
import { Tag } from "../models/tag";

export class CuratedAPI {

    public static getCuratedByCategory(paging: PagingInfo): Promise<{ data: ({ category: CuratedCategory, sets: CuratedPackage[] })[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest('/curated/byCategory', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response as { data: { category: CuratedCategory, sets: CuratedPackage[] }[], pagination: PagingInfoResponse }
        });
    }

    public static getFreeCurated(paging: PagingInfo): Promise<{ data: CuratedPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<CuratedPackage>('/curated/free', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response as { data: CuratedPackage[], pagination: PagingInfoResponse }
        });
    }

    public static getCuratedById(id: string): Promise<{ pack: CuratedPackage, topTags: Tag[] }> {
        let apiRequest = new AuthenticatedRequest('/curated/' + id, HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static likeCurated(sound: { id: string }): Promise<CuratedPackage> {
        let apiRequest = new AuthenticatedRequest<CuratedPackage>('/curated/' + sound.id + '/favorite', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static unLikeCurated(sound: { id: string }): Promise<CuratedPackage> {
        let apiRequest = new AuthenticatedRequest<CuratedPackage>('/curated/' + sound.id + '/favorite', HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static downloadCurated(soundpack: { id: string }, location: string): Promise<{ url: string }> {
        let apiRequest = new AuthenticatedRequest<{ url: string }>('/curated/' + soundpack.id + '/download', HTTPMethod.GET, APIVersion.v1_0, { location });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static downloaCuratedPreview(soundpack: { id: string }): Promise<{
        required: {
            total: number
            promo: number
            regular: number
        }, allowed: boolean, trial_applicable: boolean
    }> {
        let apiRequest = new AuthenticatedRequest<{ required: number, allowed: boolean }>('/curated/' + soundpack.id + '/download-preview', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

}