import * as React from 'react'
import { RouteComponentProps, withRouter, useHistory } from "react-router";
import { SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { DefaultProps, Divider, onTagClick, LabelHeader, Loader, ManagedAlbumRow } from "bpm-sounds-generic";
import { LabelAPI } from '../api/endpoints/label';
import { Label } from '../api';
import TrendingLabels from '../components/TrendingLabels';
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'

const LabelDetail: React.FC<RouteComponentProps<{ name: string }> & DefaultProps> = (props) => {
    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({ label: props.match.params.name })
    const [labels, setLabels] = React.useState<Label[]>()
    const [label, setLabel] = React.useState<Label>()

    const history = useHistory();

    // get & set Label
    React.useEffect(() => {
        setFilter({ ...filter, ...props.location.state, label: props.match.params.name })
        LabelAPI.getLabelByName(props.match.params.name).then(setLabel)
    }, [props.match.params.name])

    // get & set Labels
    React.useEffect(() => {
        LabelAPI.getTrendingLabels().then((data) => {
            filterMoreLabels(data)
        })
    }, [label])

    // Remove current label from more labels 
    const filterMoreLabels = (labels: Label[]) => {
        if (label) {
            const currentLabels = labels.filter(item => item.name !== label.name);
            setLabels(currentLabels)
        }
    }

    return (
        <>
            {label
                ? <LabelHeader
                    {...props}
                    label={label}
                    tags={label.top_tags}
                    onTagClick={(tag) => {
                        onTagClick(tag, filter, setFilter)
                    }}
                />
                : <Loader />
            }
            <Divider />
            <BannerRenderer location={'label:' + props.match.params.name} onItemClick={(type, id) => Analytics.trackClick(type, id)} />
            <ManagedAlbumRow context={'top_label_packs'}
                key={filter.label as string  /** Hack to forcen re-render this component when 'props.match.params.name' changes. 
            ManagedAlbumRow reloads the top packs for the label. Otherwise would just stay the same */}
                {...props}
                title='Top Packs'
                apiFunc={(paging, token) => {
                    return LabelAPI.getTopPacksForLabelName(props.match.params.name, paging, token)
                }} />
            <Divider />

            <ManagedAlbumRow context={'new_label_packs'}
                key={'Avoid Duplicate Key' + filter.label as string}
                {...props}
                title='New Packs'
                apiFunc={(paging, token) => {
                    return LabelAPI.getNewestPacksForLabelName(props.match.params.name, paging, token)
                }} />

            <Divider />

            <CreateManagedSoundList {...props} context={'label:' + props.match.params.name} pagingMode='manual'
                apiFunc={SoundAPI.getSounds}
                filter={filter} onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }} hideFilterKeys={['label']} />

            <Divider height={'5.5rem'} />
            {labels ?
                <TrendingLabels {...props} labels={labels} title='More Labels' onViewAllClick={() => {
                    history.push('/labels')
                }} />
                : null
            }
        </>
    )
}

export default withRouter(LabelDetail)