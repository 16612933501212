import React, { useEffect, useRef, useState } from 'react'
import { errorToString } from '../../../api';
import { AdminAPI, CreateCurated } from '../../../api/endpoints/admin';
import * as toastr from 'toastr'
import { DropdownButton, Button, EditPopup } from 'bpm-sounds-generic';

interface PopupCreateCuratedsetProps {
    closed: boolean;
    onClosed: (closed: boolean) => void;
    driveId: string
}

const PopupCreateCuratedset = (props: PopupCreateCuratedsetProps) => {
    const artworkInput = useRef<HTMLInputElement>(null);
    const mp3Input = useRef<HTMLInputElement>(null);
    const [uploading, setUploading] = useState<boolean>(false)
    const [curated, setCurated] = useState<CreateCurated>({ drive_id: props.driveId })
    const [categories, setCategories] = useState<{ id: string, name: string }[]>([])

    useEffect(() => {
        if (!props.closed && categories.length == 0)
            AdminAPI.getCuratedCategories().then(setCategories)
    }, [props.closed])

    const isValid = () => {
        return curated.name && curated.slug && curated.description
    }

    return (
        <EditPopup title={'Create Curated Set'} closed={props.closed} onClosed={props.onClosed} maxWidth='50rem'>
            <div className="PopupCreateCuratedset content" style={{ width: '450px' }}>
                <div className="dx-field">
                    <div className="dx-field-label">
                        <Button
                            className="upload-cover"
                            buttonText='Upload Cover'
                            loading={uploading} onClick={() => {
                                artworkInput.current && artworkInput.current.click()
                            }}
                            backgroundColor={'#1d1f1f'}
                            border={true}
                        />
                    </div>
                    <div className="dx-field-value">
                        {curated.artwork_url || 'Not Uploaded'}
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">
                        <Button
                            className="upload-demo"
                            buttonText='Upload Demo' loading={uploading} onClick={() => {
                                mp3Input.current && mp3Input.current.click()
                            }}
                            backgroundColor={'#1d1f1f'}
                            border={true}
                        />
                    </div>
                    <div className="dx-field-value">
                        {curated.demo_file_path || 'Not Uploaded'}
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">
                        Category
                    </div>
                    <div className="dx-field-value">
                        <DropdownButton buttonText={curated.category_id && categories.find(c => c.id == curated.category_id)?.name || 'Select…'}
                            menuItems={categories.map(category => {
                                return {
                                    text: category.name,
                                    selected: curated.category_id == category.id,
                                    onClick: () => {
                                        setCurated((curated) => {
                                            return { ...curated, category_id: category.id }
                                        })
                                    }
                                }
                            })} />
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">
                        Name
                    </div>
                    <div className="dx-field-value">
                        <input
                            name="input"
                            type="text"
                            value={curated.name || ''}
                            onChange={(e) => {
                                const value = e.target.value
                                setCurated((curated) => {
                                    return { ...curated, name: value, slug: value.replace(/_/gi, '').replace(/\s+/gi, '-').toLowerCase() }
                                })
                            }}
                            placeholder="Enter name"
                        />
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">
                        Slug
                    </div>
                    <div className="dx-field-value">
                        <input
                            name="input"
                            type="text"
                            value={curated.slug || ''}
                            onChange={(e) => {
                                const value = e.target.value
                                setCurated((curated) => {
                                    return { ...curated, slug: value }
                                })
                            }}
                            placeholder="Enter slug"
                        />
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">
                        Video URL
                    </div>
                    <div className="dx-field-value">
                        <input
                            name="input"
                            type="text"
                            value={curated.video_url || ''}
                            onChange={(e) => {
                                const value = e.target.value
                                setCurated((curated) => {
                                    return { ...curated, video_url: value }
                                })
                            }}
                            placeholder="Enter Youtube URL"
                        />
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">
                        Description
                    </div>
                    <div className="dx-field-value">
                        <textarea
                            name="input"
                            value={curated.description || ''}
                            onChange={(e) => {
                                const value = e.target.value
                                setCurated((curated) => {
                                    return { ...curated, description: value }
                                })
                            }}
                            disabled={uploading}
                            placeholder="Enter description"
                        />
                    </div>
                </div>
                <div className="dx-field">
                    <div className="dx-field-label">

                    </div>
                    <div className="dx-field-value">
                        <Button buttonText='Create' backgroundColor={'var(--primary)'} textColor={'white'} loading={uploading}
                            disabled={!isValid()} onClick={() => {
                                if (isValid()) {
                                    setUploading(true)
                                    AdminAPI.createCuratedSet(curated).then((data) => {
                                        window.location.href = '/curated/' + data.id
                                    }).catch(err => {
                                        setUploading(false)
                                        errorToString(err).forEach(error => {
                                            toastr.error(error)
                                        })
                                    })
                                }
                            }} />
                    </div>
                </div>
                <input ref={artworkInput} accept='image/*' type='file'
                    style={{ visibility: 'hidden', width: 0, height: 0, position: 'absolute', top: -5 }} onChange={(e) => {
                        let file = e.target.files!.length > 0 ? e.target.files![0] : undefined
                        if (file) {
                            setUploading(true)
                            AdminAPI.uploadCuratedArtwork(file).then(({ url }) => {
                                setUploading(false)
                                setCurated((curated) => {
                                    return { ...curated, artwork_url: url }
                                })
                            }).catch(err => {
                                setUploading(false)
                                errorToString(err).forEach(error => {
                                    toastr.error(error)
                                })
                            })
                        }
                        e.target.value = ''
                    }} />

                <input ref={mp3Input} accept='.mp3' type='file'
                    style={{ visibility: 'hidden', width: 0, height: 0, position: 'absolute', top: -5 }} onChange={(e) => {
                        let file = e.target.files!.length > 0 ? e.target.files![0] : undefined
                        if (file) {
                            setUploading(true)
                            AdminAPI.uploadCuratedDemo(file).then(({ url }) => {
                                setUploading(false)
                                setCurated((curated) => {
                                    return { ...curated, demo_file_path: url }
                                })
                            }).catch(err => {
                                setUploading(false)
                                errorToString(err).forEach(error => {
                                    toastr.error(error)
                                })
                            })
                        }
                        e.target.value = ''
                    }} />
            </div>
        </EditPopup>
    )
}

export default PopupCreateCuratedset