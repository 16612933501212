import * as React from "react";
import './classic-mode-banner.scss';
import { useSessionStorage } from "./useSesstionStorage";

export interface ClassicModeBannerProps {
    href: string
    platform: string
}

export function ClassicModeBanner({ href, platform }: ClassicModeBannerProps) {
    const [showClassicModeModal, setShowClassicModeModal] = useSessionStorage('NewModeModal', true);
    React.useEffect(() => {
        if (showClassicModeModal) {
            document.body.classList.add('isClassicBannerShown');
        } else {
            document.body.classList.remove('isClassicBannerShown');
        }
        return () => {
            document.body.classList.remove('isClassicBannerShown');
        };
    }, [showClassicModeModal]);

    if (!showClassicModeModal) {
        return null
    }
    return (
        <div className={'classic-mode-banner__container'}>
            <h3 className={'classic-mode-banner__container-text'}>Switch to the All-New <a href={href}><u>{platform} Platform</u></a></h3>
            <button className={'classic-mode-banner__button'} type="button" aria-label="Close" onClick={() => setShowClassicModeModal(false)}>
                <svg className="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.5 16.5 12 12 7.5 7.5M16.5 16.5 12 12l4.5-4.5" stroke="#919191" stroke-width="2" stroke-miterlimit="10" /></svg>
            </button>
        </div>
    );
}



export default ClassicModeBanner;
