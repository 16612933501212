import { AnalyticsAPI, Session } from "bpm-client-api"
import { APIVersion } from "bpm-client-api/lib/requests/request"

export class Analytics {

    static previousReferrer = window.document.referrer

    static events: {
        click: Array<Object>,
        generic: Array<Object>,
        view: Array<Object>,
        session: Array<Object>
    } =
        { click: [], view: [], session: [], generic: [] }

    static initialize() {
        setInterval(() => {
            Analytics.send()
        }, 500)
        Analytics.trackSession('start')
        window.onbeforeunload = function () {
            Analytics.trackSession('end')
            Analytics.send()
        }
    }

    static send() {
        if (Analytics.events.click.length > 0 || Analytics.events.view.length || Analytics.events.session.length || Analytics.events.generic.length) {
            if (typeof navigator.sendBeacon === 'function') {
                navigator.sendBeacon(`${Session.getAPIURL()}/${APIVersion.v3_0}/analytics/track/create`, JSON.stringify({ events: Analytics.events.click.concat(Analytics.events.view).concat(Analytics.events.session).concat(Analytics.events.generic) }))
            } else {
                AnalyticsAPI.track(Analytics.events.click.concat(Analytics.events.view).concat(Analytics.events.session).concat(Analytics.events.generic), 'create')
            }
            Analytics.events.session = []
            Analytics.events.view = []
            Analytics.events.generic = []
            Analytics.events.click = []
        }
    }

    static trackClick(entity_name: string, entity_id: string, meta: any = {}) {
        Analytics.events.click.push({
            date: new Date(),
            type: 'click',
            entity_name,
            entity_id,
            from: window.location.pathname + window.location.search,
            meta
        })
    }

    static trackGeneric(event: string, meta: any = {}) {
        Analytics.events.generic.push({
            date: new Date(),
            type: 'generic',
            event,
            from: window.location.pathname + window.location.search,
            meta
        })
        if (window.fbq) {
            window.fbq('trackCustom', event)
        }
    }

    static trackView(entity_name?: string, entity_id?: string) {
        Analytics.events.view.push({
            date: new Date(),
            type: 'view',
            entity_name,
            entity_id,
            from: window.location.pathname + window.location.search,
            referrer: Analytics.previousReferrer
        })
        if (window.fbq && !entity_name) {
            window.fbq('track', 'PageView')
        }
        if (window.ga && window.ga.getAll && window.ga.getAll().length == 1 && !entity_name) {
            const tracker = window.ga.getAll()[0]
            tracker.set('location', window.location.href);
            tracker.set('referrer', Analytics.previousReferrer);
            tracker.send('pageview')
        }
        Analytics.previousReferrer = window.location.href
    }

    static trackSession(session_type: 'end' | 'start') {
        Analytics.events.session.push({
            date: new Date(),
            type: 'session',
            session_type,
            from: window.location.pathname + window.location.search,
            screen_width: window.screen.width,
            screen_height: window.screen.height,
            agent: navigator.userAgent
        })
    }
}