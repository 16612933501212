import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { SoundPackage, Label } from "../models";
import { PagingInfoResponse, PagingInfo } from "./types";

export class LabelAPI {

    public static getLabelByName(name: string): Promise<Label> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/labels/byName/' + name, HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static getNewestPacksForLabelName(name: string, paging: PagingInfo, token?: string): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/labels/byName/' + name + '/newest', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return { ...response as { data: SoundPackage[], pagination: PagingInfoResponse }, token }
        });
    }

    public static getTopPacksForLabelName(name: string, paging: PagingInfo, token?: string): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/labels/byName/' + name + '/topPacks', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return { ...response as { data: SoundPackage[], pagination: PagingInfoResponse }, token }
        });
    }

    public static getTrendingLabels(): Promise<Label[]> {
        let apiRequest = new AuthenticatedRequest<Label>('/labels', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return <Label[]>response.data
        });
    }

}