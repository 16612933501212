import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { SoundPackage, Sound, Genre } from "../models";
import { PagingInfo, PagingInfoResponse, SoundPackageQueryAttributes } from "./types";
import { Tag } from "../models/tag";

export class SoundPackageAPI {

    public static getSoundPackages(query: SoundPackageQueryAttributes, paging: PagingInfo, token?: string): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse, token?: string }> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sound-packages', HTTPMethod.GET, APIVersion.v1_0, { ...query, ...paging });
        return apiRequest.send().then((response) => {
            return { ...response as { data: SoundPackage[], pagination: PagingInfoResponse }, token }
        });
    }

    public static getFeaturedSoundPackages(paging: PagingInfo): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/featured', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response as { data: SoundPackage[], pagination: PagingInfoResponse }
        });
    }

    public static getSoundPackagesByGenre(genreSlug: string) {
        return (paging: PagingInfo): Promise<{ data: { packs: SoundPackage[], genre: Genre }, pagination: PagingInfoResponse }> => {
            let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/byGenre/' + encodeURIComponent(genreSlug), HTTPMethod.GET, APIVersion.v1_0, paging);

            return apiRequest.send().then((response) => {
                return response as { data: { packs: SoundPackage[], genre: Genre }, pagination: PagingInfoResponse }
            });
        }
    }

    public static getNewestSoundPackages(paging: PagingInfo): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/newest', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response as { data: SoundPackage[], pagination: PagingInfoResponse }
        });
    }

    public static getDownloadedSoundPackages(paging: PagingInfo): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/downloaded', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response as { data: SoundPackage[], pagination: PagingInfoResponse }
        });
    }

    public static getSuggestedSoundPackages(): Promise<{ data: SoundPackage[] }> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/suggested', HTTPMethod.GET, APIVersion.v1_1);

        return apiRequest.send().then((response) => {
            return response as { data: SoundPackage[], pagination: PagingInfoResponse }
        });
    }

    public static getFreeSoundPackages(paging: PagingInfo): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/free', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response as { data: SoundPackage[], pagination: PagingInfoResponse }
        });
    }

    public static getTrendingSoundPackages(paging: PagingInfo): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/trending', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return response as { data: SoundPackage[], pagination: PagingInfoResponse }
        });
    }

    public static getLikedSoundPackages(paging: PagingInfo, token?: string): Promise<{ data: SoundPackage[], pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/liked', HTTPMethod.GET, APIVersion.v1_0, paging);

        return apiRequest.send().then((response) => {
            return { ...response as { data: SoundPackage[], pagination: PagingInfoResponse }, token }
        });
    }

    public static getSoundPackageById(packageId: string): Promise<{ pack: SoundPackage, topTags: Tag[] }> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sound-package/' + packageId, HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data;
        });
    }

    public static likeSoundPackage(sound: { id: string }): Promise<SoundPackage> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/' + sound.id + '/favorite', HTTPMethod.POST, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static unLikeSoundPackage(sound: { id: string }): Promise<SoundPackage> {
        let apiRequest = new AuthenticatedRequest<SoundPackage>('/sound-packages/' + sound.id + '/favorite', HTTPMethod.DELETE, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static getSimilarSoundPackages(sound: { id: string }, limit = 5): Promise<{ data: { packs: SoundPackage[], current: SoundPackage }, pagination: PagingInfoResponse }> {
        let apiRequest = new AuthenticatedRequest<Sound>('/sound-packages/' + sound.id + '/similar', HTTPMethod.GET, APIVersion.v1_1, { limit });

        return apiRequest.send().then((response) => {
            return { ...response as { data: { packs: SoundPackage[], current: SoundPackage }, pagination: PagingInfoResponse } }
        });
    }

    public static downloadSoundPackge(soundpack: { id: string }, location: string): Promise<{ url: string }> {
        let apiRequest = new AuthenticatedRequest<{ url: string }>('/sound-package/' + soundpack.id + '/download', HTTPMethod.GET, APIVersion.v1_0, { location });

        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static downloadSoundPackgePreview(soundpack: { id: string }): Promise<{
        required: {
            total: number
            promo: number
            regular: number
        }, allowed: boolean, trial_applicable: boolean
    }> {
        let apiRequest = new AuthenticatedRequest<{ required: number, allowed: boolean }>('/sound-package/' + soundpack.id + '/download-preview', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return response.data
        });
    }
}