export { Session } from './requests/request'
export * from './errors'
export * from './models'

export function errorToString(err: { type?: string, message: string }, defaultMessage = 'An unhandled error occurred. Please try again later.'): Array<string> {
    if (err && err.type == 'InvalidParametersError') {
        if ((err as any).missing_keys && (err as any).missing_keys.length) {
            return (err as any).missing_keys.map((k: string) => k + ' is missing')
        }
        return Object.keys((err as any).invalid_keys).map((key) => {
            return key + ': ' + (err as any).invalid_keys[key]
        })
    } else if (err && err.type == 'ResourceAlreadyExists' || err && err.message) {
        return [err.message]
    } else {
        return [defaultMessage]
    }
}