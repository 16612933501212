import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { DefaultProps } from "bpm-sounds-generic";
import { ManagedAlbumRow, Divider } from 'bpm-sounds-generic';
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';


const NewPacks: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    return (
        <>
            <Divider small />
            <BannerRenderer location={'new'} onItemClick={(type, id) => Analytics.trackClick(type, id)} />
            <ManagedAlbumRow
                {...props}
                context={'newest_packs'}
                title='New Packs'
                apiFunc={SoundPackageAPI.getNewestSoundPackages}
                defaultExpanded={true}
            />
            <Divider />
        </>
    )
}

export default withRouter(NewPacks)