import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from 'react-router-dom';
import Main from "./pages/Main";
import { Environment } from "./env";
import { Session, User } from "./api";
import { UsersAPI } from "./api/endpoints/user";
import { UsersAPI as BPMUsersAPI, SubscriptionInfo } from 'bpm-client-api'
import { APIError } from "./api/requests/request";
import { SoundPackageQueryAttributes } from "./api/endpoints/types";
import { Drive } from "./api/models/drive";
import Account from "./pages/account/Account";
import Billing from "./pages/account/Billing";
import { useHistory, useLocation } from 'react-router';
import { CreditInfo } from "./api/models/credit";
import AccountHeader from "./components/AccountHeader";
import { HTML5Backend } from 'react-dnd-html5-backend'
import Subscribe from "./pages/account/Subscribe";
import { DndProvider } from "react-dnd";
import { SEARCH_SVG, NEW_RELEASES_SVG, GENRE_SVG, LABELS_SVG, INSTRUMENTS_SVG, MIDI_SVG, TRENDS_SVG, FREE_PACKS_SVG, LIBRARY_SVG, FAV_SVG, SUGGESTIONS_SVG, BPM_SOUNDS_LOGO, BPM_SOUNDS_LOGO_BLACK, SOUNDS_LOGO_SVG } from "bpm-sounds-generic/assets";
import { Popup, EditModeContextProvider, SoundDownloadontextProvider, DriveContextProvider, UserContextProvider, HeaderBar, Sidebar, Loader, PlayerContextProvider, PlayerState, PlaybackState, Player, PlaybackManager, SoundDownloadManager } from "bpm-sounds-generic";
import { Analytics } from "manager/AnalyticsManager";
import { DriveManager, DRIVE_LOADED_EVENT } from "manager/drivemanager";
import { CreditManager, CREDIT_LOADED_EVENT } from "manager/creditmanager";
import { CreditContextProvider } from "context/credit";
import * as smoothscroll from 'smoothscroll-polyfill';
import { BPMRedirect } from "components/BPMRedirect";
import ClassicModeBanner from "components/classic-mode-banner";


const App = () => {
    const [theme, setTheme] = useState<'dark' | 'light'>("dark");
    const [user, setUser] = useState<User>();
    const [playerState, setPlayerState] = React.useState<PlaybackState>({ state: PlayerState.None })
    const [search, setSearch] = useState<SoundPackageQueryAttributes>({});
    const [drives, setDrives] = useState<Drive[]>([])
    const [editMode, setEditMode] = useState<boolean>(false)
    const [creditInfo, setCreditInfo] = useState(CreditManager.instance().info)
    const [soundsToDownloads, setSoundsToDownloads] = React.useState<string[]>([])
    const [sidebarExpanded, setSidebarExpanded] = useState(true)
    const history = useHistory();
    const location = useLocation()

    const newSidebarState = (newState: boolean) => {
        setSidebarExpanded(newState)
    }
    smoothscroll.polyfill();
    useEffect(() => {
        window.scrollTo(0, 0)
        Analytics.trackView()
    }, [location.pathname])

    useEffect(() => {
        DriveManager.instance().on(DRIVE_LOADED_EVENT, (drives: Drive[]) => {
            setDrives(drives.slice())
        })
        DriveManager.instance().loadDrives()
        SoundDownloadManager.getInstance().addSoundStateListener((sounds) => {
            setSoundsToDownloads(sounds.map(s => s.id))
        })
        CreditManager.instance().on(CREDIT_LOADED_EVENT, (info: CreditInfo) => {
            setCreditInfo(info)
        })
        CreditManager.instance().loadCredits()
    }, [])

    useEffect(() => {
        if (theme === 'dark') {
            document.body.classList.remove("light-theme");
            document.body.classList.add("dark-theme");
        } else {
            document.body.classList.remove("dark-theme");
            document.body.classList.add("light-theme");
        }
    }, [theme])

    const onEditToggle = () => {
        localStorage['edit_mode'] = !editMode
        setEditMode(!editMode)
    }

    Session.setUnauthorizedHandler((err) => {
        if (location.pathname.startsWith('/contest/')) {
            const [, , contest] = location.pathname.split('/')
            window.location.assign(Environment.BPM_URL + '/contest?name=' + encodeURIComponent(contest) + '&' + location.search.substr(1))
        } else if (location.pathname.startsWith('/giveaway/')) {
            const [, , giveaway] = location.pathname.split('/')
            window.location.assign(Environment.BPM_URL + '/giveaway?name=' + encodeURIComponent(giveaway) + '&' + location.search.substr(1))
        } else
            window.location.assign(Environment.BPM_URL + '/bpm-create/login?platform=create&pool_redirect=' + window.location.pathname.split('/').map(encodeURIComponent).join('/'));
    })
    Session.setNotEnoughCreditsHandler((err) => {
        Popup.creditLimit(err.trial_applicable)
    })
    Session.setSubscriptionHandler((err) => {
        if (err.current_supscription > SubscriptionInfo.None) {
            if (err.current_supscription == SubscriptionInfo.Pro) {
                return Popup.driveLimit()
            }
            Popup.upgrade().then(result => {
                if (result == 'ok') {
                    window.location.assign('/pricing')
                } else {
                }
            })

        } else {
            Popup.subscribe().then(result => {
                if (result == 'ok') {
                    window.location.assign('/pricing')
                } else {

                }
            })

        }
    })
    Session.setUserUpdatedHandler((user) => {
        setUser(user)
        setTheme(user.theme == 'black' ? 'dark' : 'light')
    })

    const loadUser = () => {
        UsersAPI.getMe().then(user => {
            if (window.bugsnagClient) {
                window.bugsnagClient.setUser(user.id.toString(), user.email, user.full_name)
            }
            if (user.is_admin) {
                if (localStorage['edit_mode'] == 'true') {
                    setEditMode(true)
                }
                jiraHelpdesk(function () {
                    var DOMContentLoaded_event = document.createEvent('Event');
                    DOMContentLoaded_event.initEvent('DOMContentLoaded', true, true);
                    window.document.dispatchEvent(DOMContentLoaded_event);
                });
            }
        }).catch((err: APIError) => {
            if (err.status == 429) {    // API Request Limit Reached, wait and reload
                setTimeout(() => {
                    loadUser()
                }, 5100)
            }
        })
    }

    useEffect(() => {
        PlaybackManager.getInstance().addPlaybackStateListener((state: PlaybackState) => {
            setPlayerState(state)
        })
        loadUser()
    }, [])

    const onLogout = () => {
        BPMUsersAPI.logout().catch(() => { }).then(() => {
            window.location.assign(Environment.BPM_URL + '/create/')
        })
    }

    if (!user) {
        return <Loader centered style={{ marginTop: '10%' }} />
    }



    const navItems = [
        {
            category_name: 'Categories',
            id: 'browse-header',
            items: [
                {
                    text: 'Browse',
                    path: '/browse',
                    iconImg: SEARCH_SVG,
                    altText: 'Search',
                },
                {
                    text: 'New Packs',
                    path: '/new',
                    iconImg: NEW_RELEASES_SVG,
                    altText: 'New Packs',
                },
                {
                    text: 'Curated',
                    path: '/curated',
                    iconImg: FREE_PACKS_SVG,
                    altText: 'Curated',
                },
                {
                    text: 'Genres',
                    path: '/genres',
                    iconImg: GENRE_SVG,
                    altText: 'Genre',
                },
                {
                    text: 'Labels',
                    path: '/labels',
                    iconImg: LABELS_SVG,
                    altText: 'Labels',
                },
                {
                    text: 'Instruments',
                    path: '/instruments',
                    iconImg: INSTRUMENTS_SVG,
                    altText: 'Instruments',
                },
                {
                    text: 'MIDI',
                    path: '/midi',
                    iconImg: MIDI_SVG,
                    altText: 'MIDI',
                },
                {
                    text: 'Trends',
                    path: '/trends',
                    iconImg: TRENDS_SVG,
                    altText: 'Trends',
                },
                {
                    text: 'Free Sounds',
                    path: '/free',
                    iconImg: FREE_PACKS_SVG,
                    altText: 'Free Sounds',
                },
            ]
        },
        {
            category_name: 'My Library',
            items: [
                {
                    text: 'My Downloads',
                    path: '/mydownloads',
                    iconImg: LIBRARY_SVG,
                    altText: 'Library',
                },
                {
                    text: 'Favorites',
                    path: '/favorites',
                    iconImg: FAV_SVG,
                    altText: 'Favorite',
                },

                {
                    text: 'Suggestions',
                    path: '/suggestions',
                    iconImg: SUGGESTIONS_SVG,
                    altText: 'Suggestions',
                },
            ]
        },
        {
            category_name: 'Drives',
            items:
                drives.map((drive) => {
                    return (
                        {
                            text: drive.name,
                            path: `/drive/${drive.id}`,
                            iconImg: SUGGESTIONS_SVG,
                            altText: drive.name
                        }
                    )
                })
        },
        {
            category_name: 'Community',
            items: [
                {
                    text: 'Contests',
                    path: '/contest',
                    altText: 'contest',
                },
                {
                    text: 'Giveaways',
                    path: '/giveaway',
                    altText: 'giveaway',
                }
            ]
        }
    ]

    return (
        <EditModeContextProvider value={editMode}>
            <UserContextProvider value={user}>
                <PlayerContextProvider value={{ media: playerState.media, state: playerState.state }}>
                    <DriveContextProvider value={drives}>
                        <CreditContextProvider value={creditInfo}>
                            <SoundDownloadontextProvider value={soundsToDownloads}>
                                <DndProvider backend={HTML5Backend}>
                                    <Switch>
                                        <Route path='/account' render={(props) => {
                                            return (
                                                <>
                                                    <BPMRedirect to="https://app.bpmcreate.com/account/overview" />
                                                </>
                                            )

                                        }} />
                                        <Route path='/billing' render={(props) => {
                                            return (
                                                <>
                                                    <BPMRedirect to="https://app.bpmcreate.com/account/payment" />
                                                </>
                                            )
                                        }} />
                                        <Route path='/subscribe/:packageId' render={(props) => {
                                            return (
                                                <>
                                                    <BPMRedirect to="https://app.bpmcreate.com/account/plan" />
                                                </>
                                            )
                                        }} />
                                        <Route path='/:page' render={() => {
                                            return <>
                                                <ClassicModeBanner
                                                    platform="BPM Create"
                                                    href="https://app.bpmcreate.com"
                                                />
                                                <HeaderBar
                                                    credits={creditInfo}
                                                    onEditToggle={onEditToggle} theme={theme}
                                                    onFilterChange={(filter) => setSearch(filter)}
                                                    navItems={navItems}
                                                    onLogout={onLogout}
                                                    sidebarExpanded={sidebarExpanded}
                                                    setSidebarExpanded={newSidebarState}
                                                    urls={{
                                                        supreme: Environment.BPM_APP_URL,
                                                        latino: Environment.LATINO_APP_URL,
                                                    }}
                                                    platform={{
                                                        url: Environment.POOL_URL,
                                                        logo_dark: BPM_SOUNDS_LOGO_BLACK,
                                                        logo_light: BPM_SOUNDS_LOGO,
                                                        icon: SOUNDS_LOGO_SVG
                                                    }}
                                                />
                                                <Sidebar
                                                    navItems={navItems}
                                                    sidebarExpanded={sidebarExpanded}
                                                    setSidebarExpanded={newSidebarState}
                                                    onNavigateToDrive={(drive: Drive) => {
                                                        history.push(`/drive/` + drive.id)
                                                    }}
                                                />
                                                <Main filter={search} />
                                                <video id='bpmlive' autoPlay controls playsInline muted
                                                    style={{
                                                        visibility: 'hidden',
                                                        top: 0, left: 0, width: 0,
                                                        height: 0, position: 'absolute'
                                                    }}
                                                />
                                            </>
                                        }} />
                                        <Route render={() => {
                                            return <Redirect to={'/browse'} />
                                        }} />
                                    </Switch>
                                    <Player onRouting={(path) => {
                                        history.push(path);
                                    }} onSoundPackageClick={({ id }) => {
                                        history.push('/packs/' + id);
                                    }} shouldShowPlayerForAudioType={(type) => {
                                        return type == 'Demo' || type == 'Contest' || type == 'mp3'
                                    }} />
                                    <Popup />
                                </DndProvider>
                            </SoundDownloadontextProvider>
                        </CreditContextProvider>
                    </DriveContextProvider>
                </PlayerContextProvider>
            </UserContextProvider>
        </EditModeContextProvider>
    );
};

export default App;

function jiraHelpdesk(callback: () => void) {
    var jhdScript = document.createElement('script');
    jhdScript.type = 'text/javascript';
    jhdScript.setAttribute('data-jsd-embedded', '');
    jhdScript.setAttribute('data-key', '70fde2ad-27ef-4031-ad5a-493e97eb9c99')
    jhdScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    jhdScript.src = 'https://jsd-widget.atlassian.com/assets/embed.js';

    jhdScript.onload = function () {
        callback();
    };
    document.getElementsByTagName('head')[0].appendChild(jhdScript);
}