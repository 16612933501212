import * as React from 'react'
import { RouteComponentProps, withRouter, Route, Switch, Redirect } from "react-router";
import Instruments from './Instruments';
import IndividualPack from './IndividualPack';
import Labels from './Labels';
import MIDI from './MIDI';
import Search from './Search';
import Browse from './Browse';
import { DefaultProps } from "bpm-sounds-generic";
import { SoundPackageQueryAttributes } from '../api/endpoints/types';
import { SoundAPI } from '../api/endpoints/sound';
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import Genres from './Genres';
import Favorites from './Favorites';
import NewPacks from './NewPacks';
import Pricing from './Pricing';
import CuratedPage from './Curated';
import LabelDetail from './LabelDetail';
import DriveDetail from './DriveDetail';
import { CuratedAPI } from '../api/endpoints/curated';
import IndividualCurated from './IndividualCurated';
import FreeSounds from './FreeSounds';
import MyDownloads from './MyDownloads';
import Suggestions from './Suggestions';
import * as toastr from 'toastr'
import Trends from './Trends';
import FeaturedPacks from '../pages/FeaturedPacks';
import GenreDetail from './GenreDetail';
import LabelPacks from './LabelPacks';
import ContestSubmit from './community/contest/ContestSubmit';
import ContestPage from './community/contest/Contest';
import { Popup, PopUpAction, SoundDownloadManager, CuratedPackageManager, SoundManager } from 'bpm-sounds-generic';
import { CreditManager } from 'manager/creditmanager';
import { KeyboardManager } from 'manager/KeyboardManager';
import SimilarPacks from './SimilarPacks';
import GiveawayPage from './community/giveaway/Giveaway';
import { UsersAPI } from 'api/endpoints/user';
import { SubscriptionInfo } from 'bpm-client-api';


function downloadMultiSounds(apiCall: Promise<{ url: string }>, preDownloadAction: () => void): Promise<boolean> {
    const toast = toastr.info('Preparing download...', undefined, { timeOut: 0, tapToDismiss: false, extendedTimeOut: 0 })
    return apiCall.then(({ url }) => {
        toastr.remove(toast)
        preDownloadAction()
        window.location.assign(url)
        return true
    }).catch((err) => {
        toastr.remove(toast)
        return false
    })
}

class Main extends React.Component<RouteComponentProps & { filter: SoundPackageQueryAttributes }> {

    deafultProps: DefaultProps = {
        onClickReroute: (location) => {
            this.props.history.push(location)
        },
        onSoundPackageClick: (sound) => {
            this.props.history.push('/packs/' + sound.slug)
        },
        onCuratedClick: (curated) => {
            this.props.history.push('/curated/' + curated.slug)
        },
        onSoundClick: (sound) => {
            this.props.history.push('/packs/' + sound.sound_package_id)
        },
        onTagClick: (tag) => {
            this.props.history.push('/search', { tags: [tag.name] } as SoundPackageQueryAttributes)
        },
        onTagGroupClick: (tag) => {
            this.props.history.push('/search', { tag_groups: [tag.name] } as SoundPackageQueryAttributes)
        },
        onBPMClick: (bpm) => {
            this.props.history.push('/search', { bpm_max: bpm, bpm_min: bpm } as SoundPackageQueryAttributes)
        },
        onKeyClick: (key) => {
            this.props.history.push('/search', { key } as SoundPackageQueryAttributes)
        },
        onGenreClick: (genre) => {
            this.props.history.push('/genre/' + encodeURIComponent(genre.name))
        },
        onSubGenreClick: (subGenre) => {
            this.props.history.push('/search', { subgenre: [subGenre.name] } as SoundPackageQueryAttributes)
        },
        onSearchChange: (search: string) => {
            this.props.history.push('/search', { search } as SoundPackageQueryAttributes)
        },
        onSoundLikeToggled: (sound) => {
            if (!SoundManager.getInstance().getMostRecentSound(sound).is_favorited) {
                return SoundAPI.likeSound(sound).then(sound => {
                    SoundManager.getInstance().updateSound(sound)
                    return sound
                })
            } else {
                return SoundAPI.unLikeSound(sound).then(sound => {
                    SoundManager.getInstance().updateSound(sound)
                    return sound
                })
            }
        },
        onSoundPackageLikeToggled: (soundPackage) => {
            if (!CuratedPackageManager.getInstance().getMostRecentCuratedPackage(soundPackage).is_favorited) {
                return SoundPackageAPI.likeSoundPackage(soundPackage).then(sound => {
                    CuratedPackageManager.getInstance().updateCuratedPackage(sound)
                    return sound
                })
            } else {
                return SoundPackageAPI.unLikeSoundPackage(soundPackage).then(sound => {
                    CuratedPackageManager.getInstance().updateCuratedPackage(sound)
                    return sound
                })
            }
        },
        onCuratedLikeToggled: (soundPackage) => {
            if (!CuratedPackageManager.getInstance().getMostRecentCuratedPackage(soundPackage).is_favorited) {
                return CuratedAPI.likeCurated(soundPackage).then(sound => {
                    CuratedPackageManager.getInstance().updateCuratedPackage(sound)
                    return sound
                })
            } else {
                return CuratedAPI.unLikeCurated(soundPackage).then(sound => {
                    CuratedPackageManager.getInstance().updateCuratedPackage(sound)
                    return sound
                })
            }
        },
        onSoundDownload: (sound, midi?: boolean) => {
            return SoundAPI.downloadSound(midi ? sound.midi! : sound, window.location.pathname).then(async ({ url }) => {
                sound = SoundManager.getInstance().getMostRecentSound(sound)
                if (midi) {
                    sound.midi!.downloaded = true
                } else {
                    sound.downloaded = true
                }
                SoundManager.getInstance().updateSound(sound)
                try {
                    await CreditManager.instance().loadCredits()
                } catch (error) {

                }
                window.location.assign(url)
                return true
            }).catch(err => {
                return false
            })
        },
        onSoundReported: (sound) => {
            return SoundAPI.reportSound(sound)
        },
        onSoundPackageDownload: (pack) => {
            return SoundPackageAPI.downloadSoundPackgePreview(pack).then((data) => {
                const promise = data.required.total == 0 ? Promise.resolve('ok' as PopUpAction) : Popup.downloadSounds(data.required.total, data.allowed, data.trial_applicable)
                promise.then((action) => {
                    if (action == 'ok' && data.allowed) {
                        downloadMultiSounds(SoundPackageAPI.downloadSoundPackge(pack, window.location.pathname), () => {
                            SoundManager.getInstance().setSoundsInPackageDownloaded(pack.id)
                        }).then(async (downloaded) => {
                            if (downloaded) {
                                try {
                                    await CreditManager.instance().loadCredits()
                                } catch (error) {

                                }
                            }
                        })
                    }
                })
                return true
            })
        },
        onCuratedDownload: (pack, midi) => {
            return CuratedAPI.downloaCuratedPreview(pack).then((data) => {
                const promise = data.required.total == 0 ? Promise.resolve('ok' as PopUpAction) : Popup.downloadSounds(data.required.total, data.allowed, data.trial_applicable)
                promise.then((action) => {
                    if (action == 'ok' && data.allowed) {
                        downloadMultiSounds(CuratedAPI.downloadCurated(pack, window.location.pathname), () => {
                            SoundManager.getInstance().setSoundsInPackageDownloaded(pack.id)
                        }).then(async (downloaded) => {
                            if (downloaded) {
                                try {
                                    await CreditManager.instance().loadCredits()
                                } catch (error) {

                                }
                            }
                        })
                    }
                })
                return true
            })
        },
        onSoundDownloadMulti: (sounds: string[], midi?: boolean) => {
            return SoundAPI.downloadMultiSoundPreview(sounds).then((data) => {
                const promise = data.required.total == 0 ? Promise.resolve('ok' as PopUpAction) : Popup.downloadSounds(data.required.total, data.allowed, data.trial_applicable)
                promise.then(async (action) => {
                    if (action == 'ok' && data.allowed) {
                        downloadMultiSounds(SoundAPI.downloadMultiSound(sounds, window.location.pathname), () => {
                            SoundManager.getInstance().setSoundsDownloaded(sounds)
                        }).then(async (downloaded) => {
                            if (downloaded) {
                                try {
                                    await CreditManager.instance().loadCredits()
                                } catch (error) {

                                }
                                SoundDownloadManager.getInstance().clear()
                            }
                        })
                    }
                })
                return true
            })
        },
    }

    componentDidMount() {
        KeyboardManager.getInstance(this.deafultProps).initialize()
    }

    shouldComponentUpdate(nextProps: { filter: SoundPackageQueryAttributes }) {
        if (nextProps.filter != this.props.filter) {
            this.props.history.push('/search', nextProps.filter)
            return false
        }
        return true
    }

    render() {
        return <>
            <div className='main-content'>
                <div className="constrain-width">
                    <Switch>
                        <Route path='/mydownloads' render={(props) => {
                            return <MyDownloads {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/suggestions' render={(props) => {
                            return <Suggestions {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/pricing' render={(props) => {
                            return <Pricing {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/browse' render={(props) => {
                            return <Browse {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/packs/:id' render={(props) => {
                            return <IndividualPack {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/genre/:name' render={(props) => {
                            return <GenreDetail {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/curated' exact render={(props) => {
                            return <CuratedPage {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/curated/:id' render={(props) => {
                            return <IndividualCurated {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/instruments' render={(props) => {
                            return <Instruments {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/genres' render={(props) => {
                            return <Genres {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/favorites' render={(props) => {
                            return <Favorites {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/search' render={(props) => {
                            return <Search {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/new' render={(props) => {
                            return <NewPacks {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/similarpacks/:id' render={(props) => {
                            return <SimilarPacks {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/featured' render={(props) => {
                            return <FeaturedPacks {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/trends' render={(props) => {
                            return <Trends {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/labels' exact render={(props) => {
                            return <Labels {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/labels/:name' render={(props) => {
                            return <LabelDetail {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/labelpacks/:name' render={(props) => {
                            return <LabelPacks {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/drive/:driveId' render={(props) => {
                            return <DriveDetail {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/midi' render={(props) => {
                            return <MIDI {...props} {...this.deafultProps} />
                        }} />
                        <Route path='/free' render={(props) => {
                            return <FreeSounds {...props} {...this.deafultProps} />
                        }} />
                        <Route exact path='/contest/submit' render={() => {
                            return (
                                <ContestSubmit />
                            )
                        }} />
                        <Route path='/contest/:slug?' render={(props) => {
                            return (
                                <ContestPage
                                    {...props}
                                />
                            )
                        }} />
                        <Route path='/giveaway/:slug?' render={(props) => {
                            return (
                                <GiveawayPage
                                    {...props}
                                />
                            )
                        }} />
                        <Route render={() => {
                            return <Redirect to={'/browse'} />
                        }} />
                    </Switch>
                </div>
            </div>
        </>
    }
}

export default withRouter(Main)