import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { SoundPackage } from '../api';
import { Loader } from 'bpm-sounds-generic';
import { SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { DefaultProps, AlbumHeader, Divider, onTagClick, ManagedSoundList } from "bpm-sounds-generic";
import { Tag } from '../api/models/tag';
import { SoundAPI } from '../api/endpoints/sound';
import { Analytics } from 'manager/AnalyticsManager';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'


const IndividualPack: React.FC<RouteComponentProps<{ id: string }> & DefaultProps> = (props) => {

    const [pack, setPack] = React.useState<SoundPackage>()
    const [allTags, setAllTags] = React.useState<Tag[]>([])
    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({ sound_package_id: props.match.params.id })

    React.useEffect(() => {
        setPack(undefined)
        SoundPackageAPI.getSoundPackageById(props.match.params.id).then(({ pack, topTags }) => {
            setPack(pack)
            setAllTags(topTags)
            Analytics.trackView('pack', pack.id)
        })
        setFilter((filter) => {
            return { ...filter, sound_package_id: props.match.params.id }
        })
    }, [props.match.params.id])

    if (!pack) {
        return <Loader centered style={{ marginTop: 20 }} />
    }

    return (
        <>
            <AlbumHeader {...props} pack={pack} tags={allTags} selectedTags={filter.tags}
                onTagClick={(tag) => {
                    onTagClick(tag, filter, setFilter)
                }} onSoundPackageLikeToggled={props.onSoundPackageLikeToggled} />
            <Divider />
            <CreateManagedSoundList key={filter.sound_package_id} {...props} apiFunc={SoundAPI.getSounds} filter={filter} onFilterChange={(newFilter) => {
                setFilter((filter) => {
                    return { ...filter, ...newFilter }
                })
            }} context={'pack'} hideFilterKeys={['sound_package_id']} />
        </>
    )
}

export default withRouter(IndividualPack)

