import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { DefaultProps, Divider, SoundPackageQueryAttributes, SoundDisplayAttributes, AlbumRow, CuratedPackage } from 'bpm-sounds-generic';
import { SoundAPI } from 'api/endpoints/sound';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'
import { SoundPackageAPI } from 'api/endpoints/soundpackage';

const Suggestions: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes>({
        suggested: true
    })
    const [loading, setLoading] = React.useState(true)
    const [packs, setPacks] = React.useState<CuratedPackage[]>([])

    React.useEffect(() => {
        setLoading(true)
        SoundPackageAPI.getSuggestedSoundPackages().then((packs) => {
            setPacks(packs.data)
            setLoading(false)
        })
    }, [])
    return (
        <div className="NewPacks">
            <Divider small />
            <AlbumRow {...props} context={'suggested_packs'} viewAll={false}
                title='Suggested Packs' soundPackages={packs} loading={loading} />
            <Divider small />
            <CreateManagedSoundList pagingMode='manual'
                hideFilterKeys={['suggested']}
                {...props} title='Suggested Sounds' apiFunc={SoundAPI.getSounds} context={'suggested'}
                filter={filter} onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }} />
            <Divider />
        </div>
    )
}

export default withRouter(Suggestions)