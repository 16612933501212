import qs from 'query-string'

export function setGiveawayCookie() {
    if (qs.parse(window.location.search)['ref']) {
        document.cookie = `giveaway_ref=${qs.parse(window.location.search)['ref']};domain=.bpmsupreme.com`
    }
}

export function getGiveawayCookie() {
    const name = "giveaway_ref=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return undefined;
}