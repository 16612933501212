import React, { useEffect, useRef, useState } from 'react';
import { Title } from "bpm-sounds-generic";
import { Label } from "../api";
import { useHistory } from "react-router";
import { CHEVRON_LEFT_SVG } from "bpm-sounds-generic/assets";

interface TrendingLabelProps {
    title: string;
    viewAll?: boolean;
    onViewAllClick?: () => void;
    loading?: boolean;
    subtitle?: string; // description underneath
    emptyPlaceholder?: string;
    labels: Label[];
}

const TrendingLabels = (props: TrendingLabelProps) => {
    const [rightMargin, setRightMargin] = useState(0);
    const [showButtonLeft, setShowButtonLeft] = useState(false);
    const [showButtonRight, setShowButtonRight] = useState(true);
    const [viewAll, setViewAll] = useState(props.viewAll || false)
    const buttonLeft = useRef<HTMLDivElement>(null);
    const buttonRight = useRef<HTMLDivElement>(null);

    const history = useHistory();
    const labelCard = useRef<HTMLDivElement>(null)
    const labelsRef = useRef<HTMLDivElement>(null)

    const computeRightMargin = () => {
        if (
            labelsRef.current &&
            labelCard.current
        ) {
            const rightMarginAsArray = window
                .getComputedStyle(labelCard.current)
                .getPropertyValue("margin-right")
                .split("");
            const rightMargin = parseInt(
                rightMarginAsArray[0] + rightMarginAsArray[1]
            );
            return rightMargin;
        }
        return 0
    };

    var scrollRequestCode = React.useRef(0);
    useEffect(() => {
        setRightMargin(computeRightMargin())
    }, [props.labels])

    const getNewOffset = (start: number, offset: number): number => {
        let rest = start % offset;
        return rest < Math.abs(offset) / 2
            ? offset - rest
            : offset - rest + Math.abs(offset);
    };

    const easeInOutQuad = (
        currentTime: number,
        startValue: number,
        offset: number,
        duration: number
    ) => {
        //https://stackoverflow.com/questions/52747018/horizontal-scrolling-using-buttons-in-reactjs
        currentTime /= duration / 2;
        if (currentTime < 1) {
            return (offset / 2) * currentTime * currentTime + startValue;
        }
        currentTime--;
        return (
            (-offset / 2) * (currentTime * (currentTime - 2) - 1) + startValue
        );
    };

    const scrollLeft = (
        element: HTMLDivElement,
        offset: number,
        duration: number
    ) => {
        var start = element.scrollLeft,
            currentTime = 0,
            increment = 20;
        offset = getNewOffset(start, offset);
        let _requestCode = ++scrollRequestCode.current;

        var animateScroll = function () {
            if (_requestCode !== scrollRequestCode.current) {
                return;
            }
            currentTime += increment;
            var val = easeInOutQuad(currentTime, start, offset, duration);
            element.scrollLeft = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    };

    const updateOffset = () => {
        if (
            labelsRef.current &&
            buttonLeft.current &&
            buttonRight.current
        ) {
            if (labelsRef.current.scrollLeft <= 10 && showButtonLeft) {    // 0...10 => hide, 11, 12.. => show it
                setShowButtonLeft(false)
            } else if (labelsRef.current.scrollLeft > 10 && !showButtonLeft) {
                setShowButtonLeft(true)
            }

            const maxScrollLeft = (labelsRef.current.scrollWidth - labelsRef.current.clientWidth) - rightMargin

            if (labelsRef.current.scrollLeft < maxScrollLeft - 10) {
                setShowButtonRight(true)
            }
            else if (labelsRef.current.scrollLeft >= maxScrollLeft) {
                setShowButtonRight(false)
            }
        }
    };
    return (
        <div className="TrendingLabels">
            <div className="header">
                <div className="left-container">
                    <Title titleText={props.title} viewAll={viewAll ? 'less' : 'all'} onClickViewAll={() => {
                        if (props.onViewAllClick) {
                            return props.onViewAllClick()
                        }
                        viewAll ? setViewAll(false) : setViewAll(true)
                    }} />
                </div>
                {
                    props.labels && props.labels.length <= 1 ? null : //TODO: Change this to 7;
                        <div className="right-container">
                            <span
                                className={`left-button ${showButtonLeft ? 'background-crimson-bright cursor-pointer' : 'album-button-disabled'}`}
                                ref={buttonLeft}
                                onClick={() => {
                                    labelsRef.current &&
                                        labelCard.current &&
                                        scrollLeft(
                                            labelsRef.current,
                                            -labelCard.current.offsetWidth * 4.30 -
                                            rightMargin,
                                            500
                                        );
                                }}
                            >
                                <img className="no-select" src={CHEVRON_LEFT_SVG} alt="" />
                            </span>
                            <span
                                className={`right-button ${showButtonRight ? 'background-crimson-bright cursor-pointer' : 'album-button-disabled'}`}
                                ref={buttonRight}

                                onClick={() => {
                                    labelsRef.current &&
                                        labelCard.current &&
                                        scrollLeft(
                                            labelsRef.current,
                                            labelCard.current.offsetWidth * 4.30 +
                                            rightMargin,
                                            500
                                        );
                                }}>
                                <img className="no-select" src={CHEVRON_LEFT_SVG} alt="" />
                            </span>
                        </div>
                }
            </div>
            <div className="labels no-scrollbar" onScroll={() => updateOffset()} ref={labelsRef}>
                {props.labels.map((label) => {
                    return (
                        <div className="labelCard" key={label.id} ref={labelCard} onClick={() => {
                            history.push('/labels/' + label.name)
                        }}>
                            <img src={label.logo_url} alt="" className="label" />
                        </div>
                    )
                })}
            </div>
        </div >
    )
}

export default TrendingLabels;