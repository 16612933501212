import { PlaybackManager, PlayerState, DefaultProps } from "bpm-sounds-generic";
import $ from 'jquery'

$.expr.pseudos.offscreen = function (el) {
    var rect = el.getBoundingClientRect();
    return (
        (rect.x + rect.width) < 0
        || (rect.y + rect.height) < 0
        || (rect.x > window.innerWidth || (rect.y + rect.height) > (window.innerHeight)) || (rect.y + rect.height) <= window.getStickyAndHeaderHeight()
    );
};
export class KeyboardManager {
    static _instance: KeyboardManager

    static optionalInstance(): KeyboardManager | undefined {
        return KeyboardManager._instance
    }

    static getInstance(props: DefaultProps) {
        if (!KeyboardManager._instance) {
            KeyboardManager._instance = new KeyboardManager(props)
        }
        return KeyboardManager._instance!
    }

    public isMetaKeyPressed: boolean = false

    private constructor(private props: DefaultProps) {

    }

    initialize() {
        let keyDown = false
        document.addEventListener('keydown', (e) => {
            if (keyDown == true) {
                if ((e.key !== 'ArrowLeft') && (e.key !== 'ArrowRight')) {
                    return
                }
            }


            this.isMetaKeyPressed = e.metaKey || e.ctrlKey
            if ($(':focus').length || ($('li:focus').length && e.key != 'm')) {
                return
            }
            keyDown = true
            const progress = PlaybackManager.getInstance().getProgress()
            const duration = PlaybackManager.getInstance().getDuration()

            switch (e.key) {

                case 'ArrowRight': {
                    duration &&
                        PlaybackManager.getInstance().seek(Math.min(duration - 0.01, progress + 1))
                    break;
                }
                case 'ArrowDown': {
                    this.keyDown(e)
                    break;
                }
                case 'ArrowLeft': {
                    PlaybackManager.getInstance().seek(Math.max(0.01, progress - 1))
                    break;
                }
                case 'ArrowUp': {
                    this.keyUp(e)
                    break;
                }
                case ' ': {
                    this.space(e)
                    break;
                }
                case 'f': {
                    this.f(e);
                    break;
                }
                case 'p': {
                    this.p(e);
                    break;
                }
                case 'd': {
                    this.d(e);
                    break;
                }
                case 'm': {
                    this.m(e);
                    break;
                }
                case 's': {
                    this.s(e);
                    break;
                }
            }
        })
        document.addEventListener('keyup', (e) => {
            keyDown = false
            this.isMetaKeyPressed = false
        })
        document.addEventListener('visibilitychange', (e) => {
            keyDown = false
            this.isMetaKeyPressed = false
        })
    }

    s(e: Event) {
        if (PlaybackManager.getInstance().getTrack() && this.props.onSoundDownload) {
            this.stopEvent(e)
        }
    }

    m(e: Event) {
        if (PlaybackManager.getInstance().getTrack()) {
            this.stopEvent(e)
            if ($('.sound-item-playing .sound-more-menu.open').length) {
                $('.sound-item-playing .sound-more-menu').click()
            } else {
                $('.sound-item-playing .sound-more-menu').click()
                $('.DropdownMenu li').focus()
            }
        }
    }

    d(e: Event) {
        if (PlaybackManager.getInstance().getTrack() && this.props.onSoundDownload) {
            this.stopEvent(e)
        }
    }

    p(e: Event) {
        if (PlaybackManager.getInstance().getTrack() && this.props.onSoundDownload) {
            let sound = PlaybackManager.getInstance().getTrack()!.sound
            if (PlaybackManager.isSound(sound)) {
                this.stopEvent(e)
                this.props.onSoundDownload(sound)
            }
        }
    }

    f(e: Event) {
        if (PlaybackManager.getInstance().getTrack() && this.props.onSoundLikeToggled) {
            let sound = PlaybackManager.getInstance().getTrack()!.sound
            if (PlaybackManager.isSound(sound)) {
                this.stopEvent(e)
                this.props.onSoundLikeToggled(sound)
            }
        }
    }

    space(e: Event) {
        if (PlaybackManager.getInstance().getPlayingState() == PlayerState.Playing) {
            PlaybackManager.getInstance().pause()
            this.stopEvent(e)
        } else if (PlaybackManager.getInstance().play()) {
            this.stopEvent(e)
        }
    }

    keyUp(e: Event) {
        if (PlaybackManager.getInstance().rewind()) {
            this.stopEvent(e)
            if ($('.sound-item-playing').length && $('.sound-item-playing').is(':offscreen')) {
                $('.sound-item-playing')[0].scrollIntoView({ behavior: 'auto', block: 'center' })
            }
        }
    }

    keyDown(e: Event) {
        if (PlaybackManager.getInstance().forward()) {
            this.stopEvent(e)
            if ($('.sound-item-playing').length && $('.sound-item-playing').is(':offscreen')) {
                $('.sound-item-playing')[0].scrollIntoView({ behavior: 'auto', block: 'center' })
            }
        }
    }

    stopEvent(e: Event) {
        e.stopPropagation()
        e.preventDefault()
        e.stopImmediatePropagation()
    }
}