import * as React from 'react'
import { Tag } from '../api/models/tag';
import { MetaAPI } from '../api/endpoints/meta';
import * as toastr from 'toastr'
import { errorToString } from '../api';
import { Loader } from 'bpm-sounds-generic';
import { DefaultProps, ButtonPanel } from "bpm-sounds-generic";
import { RouteComponentProps } from 'react-router';
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';

const Instruments: React.FC<DefaultProps & RouteComponentProps> = (props) => {

    const [tags, setTags] = React.useState<Tag[]>()
    React.useEffect(() => {
        MetaAPI.getTags({ category: 'Instruments' }).then(({ data }) => setTags(data)).catch(err => {
            toastr.error(errorToString(err).join('\n'))
        })
    }, [])
    if (!tags) {
        return <Loader centered style={{ marginTop: '10%' }}
            size={'4rem'}
        />
    }
    const groups = Array.from(tags.reduce((set, tag) => {
        set.add(tag.tag_group_id!)
        return set
    }, new Set<string>()))
    return <>
        <BannerRenderer location={'instruments'} onItemClick={(type, id) => Analytics.trackClick(type, id)} noDivider />
        {groups.map(group => {
            const currentTags = tags.filter(t => t.tag_group_id == group)
            return <ButtonPanel key={group}
                header={currentTags[0].TagGroup!.name} onHeaderClick={() => {
                    props.onTagGroupClick(currentTags[0].TagGroup!)
                }}
                items={currentTags} onClick={(tag) => {
                    props.onTagClick(tag)
                }}
            />
        })}
    </>
}

export default Instruments
