import { User } from "bpm-sounds-generic";
import { AuthenticatedRequest, HTTPMethod, APIVersion, Session } from "../requests/request";
import { Environment } from "../../env";
import { CreditInfo } from "../models/credit";
import { SubscriptionInfo } from "bpm-client-api";

export interface MembershipInfo {
    start_date: Date
    membership_type: string
    membership_info: SubscriptionInfo,
    remainingDays: number
    interval: string
    next_billing: Date
    title: string
    status: string,
    order: {
        id: number,
        user_id: number,
        transaction_id: number,
        plan_name: string,
        plan_period: number,
        status: string,
        paid_amount: number,
        method: string,
        mode: string,
        from: string,
        created_at: string,
        expire_date: string,
        gateway_id: string,
        updated_at: string,
        is_last_status: boolean,
        gw_version: number
        package_id: number
        next_cycle_package_change?: number
    }
}

export class UsersAPI {

    /**
     * Fetches the user
     * @returns {Promise<User>} The Promise contains an APIError if rejected
     */
    public static getMe(): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/users/me', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            Session.getUserUpdatedHandler()(response.data)
            return <User>response.data;
        });
    }

    public static getCredits(): Promise<CreditInfo> {
        let apiRequest = new AuthenticatedRequest<User>('/users/credits', HTTPMethod.GET, APIVersion.v1_0);

        return apiRequest.send().then((response) => {
            return <CreditInfo>response.data;
        });
    }

    public static updateTheme(theme: 'black' | 'white'): Promise<User> {
        let apiRequest = new AuthenticatedRequest<User>('/users/me/theme', HTTPMethod.PUT, APIVersion.v1_0, undefined, { theme });
        apiRequest.apiUrl = Environment.GENERIC_API
        return apiRequest.send().then((response) => {
            //Session.getUserUpdatedHandler()(response.data)
            return <User>response.data;
        });
    }

    public static getMembership(): Promise<{ activeSubscription: MembershipInfo, trialApplicable: boolean }> {
        let apiRequest = new AuthenticatedRequest<MembershipInfo>('/users/me/membership', HTTPMethod.GET, APIVersion.v1_1);
        apiRequest.preventInvalidSubscriptionHandler = true;
        return apiRequest.send().then((response) => {
            return response.data;
        })
    }

}