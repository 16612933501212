import { GiveawayAPI } from 'api/endpoints/giveaway';
import { Giveaway, GiveawayEntry } from 'api/models/giveaway';
import { APIVersion } from 'bpm-client-api/lib/requests/request';
import { Button, Popup, shareLink } from 'bpm-sounds-generic';
import { CLOSE_SVG, SOUNDS_LOGO_SVG } from 'bpm-sounds-generic/assets';
import { Environment } from 'env';
import React, { useState } from 'react'
import { getGiveawayCookie } from 'util/giveaway';

export function PopupGiveawayEntry(props: { giveaway: Giveaway, onClose: (done: boolean) => void }) {

    const [zip, setZip] = useState<string>('')
    const [errors, setErrors] = useState<{ zip?: string }>({})
    const [entry, setEntry] = useState<GiveawayEntry>()
    const [loading, setLoading] = useState<boolean>(false)
    const [height, setHeight] = useState(200)
    const [width, setWidth] = useState(300)
    const { giveaway, onClose } = props

    const submit = () => {
        setErrors({})
        let doUpdate = true
        if (!zip || zip.trim() == '') {
            setErrors((err) => {
                return { ...err, zip: 'Please enter your zip code' }
            })
            doUpdate = false
        } else {
            setLoading(true)
            GiveawayAPI.submit(giveaway.id, zip, getGiveawayCookie()).then((entry) => {
                setEntry(entry)
                setLoading(false)
            }).catch(err => {
                setLoading(false)
                console.log(err)
                Popup.giveawaySubmissionError(err.message)
            })
        }
    }

    return (
        <>
            <div className='Giveaway-Entry-Popup-dark-background' />
            <div className={'Giveaway-Entry-Popup'}>
                <div>
                    <div onClick={() => {
                        onClose(false)
                    }} className="close">
                        <img
                            style={{ float: 'right' }}
                            src={CLOSE_SVG}
                            width={12} alt="Close" />
                    </div>
                </div>
                <div>
                    <div style={{ height, width, position: 'relative' }}>
                        <div className={'SignUp ' + (entry ? 'closed' : 'show')}>
                            <div className='wrapper' ref={(ref) => {
                                ref && setWidth(ref.clientWidth)
                            }}>
                                <h1>{giveaway.title}</h1>
                                <div className="subtext">This giveaway is for US residents only.</div>
                                <form>
                                    <label className="text-label" htmlFor="zip">Please verify your zip code to continue.<br /> Only one entry per person is permitted.</label>
                                    <input name="zip" type="text" id="zip" placeholder='Zip Code' onChange={(e) => setZip(e.target.value)} value={zip} />
                                    <div className='input-error'>{errors.zip}</div>

                                    <Button buttonText={'Submit'} loading={loading} onClick={submit}></Button>
                                </form>
                            </div>
                        </div>
                        <div className={'Entry ' + (entry ? 'show' : 'closed')}>
                            <div className='wrapper' ref={(ref) => {
                                ref && setHeight(ref.clientHeight)
                            }}>
                                <h1 className="popup">
                                    Thanks for entering!
                                </h1>
                                <p style={{ marginBottom: 20 }}>Please check your email to confirm your entry.</p>
                                <div className="vl" />
                                <p className="chances">Get More Chances to Win:</p>
                                <div className="list">
                                    {giveaway.GiveawayShareLinks.map((link) => {
                                        return <div className="item" key={link.id}>
                                            <div className="text">{link.title}</div>
                                            <Button buttonText={link.score == 1 ? '+1 Entry' : `+${link.score} Entries`} onClick={() => {
                                                window.open(`${Environment.GENERIC_API}/${APIVersion.v3_0}/giveaway/${giveaway.slug}/${entry?.share_token}/entry?type=${link.slug}`)
                                            }}></Button>
                                        </div>
                                    })}
                                </div>
                                <div className="vl" />
                                <p className="chances">Refer friends for even more extra entries!</p>
                                <p style={{ fontWeight: 600, fontSize: '1.9rem' }}>Get 1 extra entry for every friend who enters using this link:</p>
                                <p className="link" style={{}} onClick={() => {
                                    shareLink(`${Environment.POOL_URL.replace('create.bpmsupreme.com', 'bpmcreate.com')}/g/${entry?.share_token}`);
                                }}>{Environment.POOL_URL.replace('create.bpmsupreme.com', 'bpmcreate.com')}/g/{entry?.share_token}</p>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}