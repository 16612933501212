import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { DefaultProps } from "bpm-sounds-generic";
import { ManagedAlbumRow, Divider } from 'bpm-sounds-generic';

const FeaturedPacks: React.FC<RouteComponentProps & DefaultProps> = (props) => {

    return (
        <>
            <Divider small />
            <ManagedAlbumRow {...props} context={'featured_packs'} title='Featured Packs' apiFunc={SoundPackageAPI.getFeaturedSoundPackages} defaultExpanded={true} />
            <Divider />
        </>
    )
}

export default withRouter(FeaturedPacks)