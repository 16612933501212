import * as React from 'react'
import { MetaAPI } from '../api/endpoints/meta';
import * as toastr from 'toastr'
import { errorToString, Genre } from '../api';
import { RouteComponentProps } from 'react-router';
import { ButtonPanel, Loader, DefaultProps } from 'bpm-sounds-generic';
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';

const Genres: React.FC<DefaultProps & RouteComponentProps> = (props) => {

    const [genres, setGenres] = React.useState<Genre[]>()
    React.useEffect(() => {
        MetaAPI.getGenres().then(setGenres).catch(err => {
            toastr.error(errorToString(err).join('\n'))
        })
    }, [])
    if (!genres) {
        return <Loader centered style={{ marginTop: '10%' }}
            size={'4rem'}
        />
    }
    return <>
        <BannerRenderer location={'genres'} onItemClick={(type, id) => Analytics.trackClick(type, id)} noDivider />
        {genres.map(genre => {
            let subGenres = genre.SubGenres
            if (subGenres.length == 0) {
                subGenres = [genre]
            }
            return <ButtonPanel key={genre.id}
                header={genre.name} onHeaderClick={() => {
                    props.onGenreClick(genre)
                }} items={subGenres} onClick={(subgenre) => {
                    if (genre.SubGenres.length == 0) {
                        props.onGenreClick(genre)
                    } else
                        props.onSubGenreClick(subgenre)
                }}
            />
        })}
    </>
}

export default Genres
