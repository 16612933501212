import React, { useContext, FormEvent, useRef, useState } from 'react';
import { SUBMIT_PNG, CHEVRON_DOWN_SVG } from "bpm-sounds-generic/assets";
import { ContestAPI } from '../../../api/endpoints/contest';
import { Contest } from '../../../api/models/contest';
import { Popup, useOnClickOutside, Loader, DropdownMenu, UserContext } from 'bpm-sounds-generic';

const ContestSubmit = () => {
    const user = useContext(UserContext)
    const [fullName, setFullName] = useState<string>(user.full_name)
    const [fileUpload, setFileUpload] = useState<FileList | null>()
    const [username, setUsername] = useState(user.username);
    const [errors, setErrors] = useState<{ fullname?: string, username?: string, remixtitle?: string, selectedcontest?: string, upload?: string }>({})
    const [showDropdown, setShowDropdown] = useState(false)
    const dropdownMenu = useRef<HTMLDivElement>(null)

    const [currentContests, setCurrentContests] = useState<Contest[]>()
    const [selectedContest, setSelectedContest] = useState<Contest>()

    const [remixTitle, setRemixTitle] = useState<string>('')
    const [remixDescription, setRemixDescription] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)

    useOnClickOutside(dropdownMenu, () => setShowDropdown(false));

    const url = window.location.href
    const slug = url.split('?')[1]

    const [multipleFilesError, setMultipleFilesError] = useState<boolean>(false)

    React.useEffect(() => {
        if (multipleFilesError) Popup.contestSubmissionDuplicate()
    }, [multipleFilesError])

    React.useEffect(() => {
        ContestAPI.getContest().then(result => {
            setCurrentContests(result.current)

            const contestFromURL = result.current.filter(contest => contest.slug === slug)[0]
            if (contestFromURL) {
                setSelectedContest(contestFromURL)
            }

        }).catch((err) => {
            console.log(err)
        })
    }, [])

    const submit = (e: FormEvent<HTMLFormElement>) => {
        setErrors({})
        e.preventDefault()
        let doUpdate = true
        if (!fullName || fullName.trim() == '' || !fullName.includes(' ')) {
            setErrors((err) => {
                return { ...err, fullname: 'Please enter your full name' }
            })
            doUpdate = false
        }
        else if (!remixTitle || remixTitle.trim() == '') {
            setErrors((err) => {
                return { ...err, remixtitle: 'Please enter a title for your track' }
            })
            doUpdate = false
        }
        else if (!username || username.trim() == '') {
            setErrors((err) => {
                return { ...err, username: 'Please enter your username' }
            })
            doUpdate = false
        }
        else if (!selectedContest) {
            setErrors((err) => {
                return { ...err, selectedcontest: 'Please select a contest' }
            })
            doUpdate = false
        }
        else if (!fileUpload || !['audio/x-wav', 'audio/wav', 'audio/wave', 'audio/vnd.wave', 'audio/mpeg', 'audio/MPA', 'audio/mpa-robust'].some(fileType => fileType.includes(fileUpload[0].type))) {
            setErrors((err) => {
                return { ...err, upload: 'Please select an mp3 or wav file.' }
            })
            doUpdate = false
        }
        else if (selectedContest) {
            if (multipleFilesError) return Popup.contestSubmissionDuplicate()
            setLoading(true)
            ContestAPI.submit(selectedContest.id, remixTitle, fileUpload[0], remixDescription).then(response => {
                Popup.contestSubmission(selectedContest.slug)
                setLoading(false)
            })
                .catch(err => {
                    setLoading(false)
                    console.log(err)
                    Popup.contestSubmissionError(err.message)
                    setFileUpload(null)
                })
        }
    }

    const header = "Submit Your Entry"
    const subtext = "For a chance to win prizes and more."

    return (
        <div className="Submit">
            <div className="left" style={{ backgroundImage: `url(${SUBMIT_PNG})` }}>
                <h1 className="header-main">{header}</h1>
                <div className="subtext-main">{subtext}</div>
            </div>
            <div className="right">
                <div className="content">
                    <h1 className="header-main">{header}</h1>
                    <div className="subtext-main">{subtext}</div>

                    <div className="title">Submit your entry by choosing from the dropdown below.</div>
                    <div className="subtext">Only one entry per person per contest is permitted.</div>
                    <form onSubmit={submit}>
                        <label className="text-label">Contest (Choose One)</label>
                        <div className="dropdown-wrapper" ref={dropdownMenu}>
                            <div className="dropdown-input" onClick={() => setShowDropdown(!showDropdown)}>
                                <span>
                                    {selectedContest?.title}
                                </span>
                                <img className="chevron" src={CHEVRON_DOWN_SVG} alt="" />
                            </div>
                            <div className='input-error'>{errors.selectedcontest}</div>

                            <div className={`dropdown ${showDropdown ? 'slide-down' : 'slide-up'}`} >
                                {
                                    currentContests ?
                                        <DropdownMenu
                                            menuItems={[
                                                ...currentContests.map((contest) => {
                                                    return (
                                                        {
                                                            text: contest.title, onClick: () => {
                                                                setSelectedContest(contest)
                                                                setShowDropdown(false)
                                                            }
                                                        }
                                                    )
                                                })
                                            ]}
                                        />
                                        :
                                        <DropdownMenu
                                            menuItems={[
                                                {
                                                    text: 'No current contests', onClick: () => {
                                                        setShowDropdown(false)
                                                    }
                                                }
                                            ]}
                                        />}
                            </div>
                        </div>

                        <label className="text-label" htmlFor="full-name">Full Name</label>
                        <input name="full-name" type="text" id="full-name" onChange={(e) => setFullName(e.target.value)} value={fullName} />
                        <div className='input-error'>{errors.fullname}</div>


                        <label className="text-label" htmlFor="username">Username</label>
                        <input id="username" type="text" onChange={(e) => setUsername(e.target.value)} value={username} />
                        <div className='input-error'>{errors.username}</div>


                        <label className="text-label" htmlFor="remix-title">Title of Your Track</label>
                        <input id="remix-title" type="text" onChange={(e) => setRemixTitle(e.target.value)} value={remixTitle} />
                        <div className='input-error'>{errors.remixtitle}</div>

                        <label className="text-label" htmlFor="remix-description">Short Description of Your Track</label>
                        <textarea name="remix-description" id="remix-description"
                            onChange={(e) => setRemixDescription(e.target.value)} value={remixDescription}
                        >
                        </textarea>

                        <label className="text-label" htmlFor="remixDescription">Upload Your Track as a mp3 or WAV (30mb limit)</label>
                        <div className="flex">
                            <div className="fake-input upload" id="upload">
                                <span>
                                    {fileUpload && fileUpload[0].name}
                                </span>

                            </div>

                            <label htmlFor="upload-file" className="select-btn" onClick={() => {
                                if (fileUpload) setMultipleFilesError(true)
                            }}>Upload</label>
                            {
                                !fileUpload ?
                                    <input id="upload-file" type="file" style={{ display: 'none' }} onChange={(e) => {
                                        setFileUpload(e.target.files)
                                    }} />
                                    : null
                            }

                        </div>
                        <div className='input-error'>{errors.upload}</div>
                        <button className="save-btn" type="submit" value="save">
                            {loading ?
                                <Loader white={true} size={'4rem'} />
                                : 'Submit'}
                        </button>

                    </form>
                </div>
            </div>
            <Popup />
        </div >
    )
}

export default ContestSubmit