import * as React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { DefaultProps } from "bpm-sounds-generic";
import { SoundAPI } from '../api/endpoints/sound';
import { SoundPackageAPI } from '../api/endpoints/soundpackage';
import { SoundPackageQueryAttributes, SoundDisplayAttributes } from '../api/endpoints/types';
import { ManagedAlbumRow, Divider } from 'bpm-sounds-generic';
import { BannerRenderer } from "bpm-sounds-generic";
import { Analytics } from 'manager/AnalyticsManager';
import CreateManagedSoundList from '../components/CreateManagedSoundlist'

const Trends: React.FC<RouteComponentProps & DefaultProps> = (props) => {
    const [filter, setFilter] = React.useState<SoundPackageQueryAttributes & SoundDisplayAttributes & { trending: boolean }>({ trending: true })

    React.useEffect(() => {
        setFilter({ ...filter, ...props.location.state, trending: true })
    }, [props.location.state])

    return (
        <>
            <Divider small />
            <BannerRenderer location={'trends'} onItemClick={(type, id) => Analytics.trackClick(type, id)} />
            <ManagedAlbumRow {...props} context={'trending_packs'} title='Trending Packs' apiFunc={SoundPackageAPI.getTrendingSoundPackages} />
            <Divider />
            <CreateManagedSoundList pagingMode='manual' hideFilterKeys={['trending']}
                {...props} title='Trending Sounds' apiFunc={SoundAPI.getTrendingSounds} context={'trends'}
                filter={filter} onFilterChange={(newFilter) => {
                    setFilter((filter) => {
                        return { ...filter, ...newFilter }
                    })
                }} />
        </>
    )
}


export default withRouter(Trends)