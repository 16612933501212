import * as React from 'react'
import { Label } from '../api/models/label'
import { Button } from 'bpm-sounds-generic'
import { useHistory } from "react-router";
import { SoundPackageQueryAttributes } from '../api/endpoints/types';
import Labels from 'pages/Labels';
import { isPropertySignature } from 'typescript';
import { useEffect, useState } from 'react';
import { divide } from 'lodash';
import Search from 'pages/Search';

interface LabelProps {
    labels: Label[]
    search?: string;
}


const LabelsComponent = (props: LabelProps) => {
    const [labels, setLabels] = useState<Label[]>([])

    useEffect(() => {
        if (props.search) {
            const filteredLabels = props.labels.filter(label => {
                return label.name.toLowerCase().includes(props.search!.toLowerCase())
            })
            setLabels(filteredLabels)
        }
        else setLabels(props.labels)
    }, [props.search])


    useEffect(() => {
        console.log(labels)
    }, [labels])
    const history = useHistory()
    return (
        <div className="LabelComponent">
            {labels.length <= 0
                ? <div className="search-not-found">Search not found</div>
                : <div className="labels-grid">
                    {labels.map((label) => {
                        return (
                            <div className="card" key={label.id} style={{ background: `linear-gradient(45deg, ${label.gradient_color})` }}>
                                <div
                                    className="image"
                                    onClick={() => {
                                        history.push('/labels/' + label.name)
                                    }} >
                                    <img className="logo" src={label.logo_url} />
                                </div>
                                <div className="text-content">
                                    <div className="title" onClick={() => {
                                        history.push('/labels/' + label.name)
                                    }}>{label.name}</div>
                                    <div className="packCount" onClick={() => {
                                        history.push('/labels/' + label.name)
                                    }}>{label.soundpackage_count + ' ' + (label.soundpackage_count === 1 ? ' Pack' : '  Packs')}</div>
                                    <div className="tags">

                                        {/* {label.top_tags && label.top_tags.map((tag) => {
                                        return (
                                            <div key={tag.id}>
                                                <Button
                                                    buttonText={tag.name}
                                                    onClick={() => {
                                                        history.push('/labels/' + label.name, { tags: [tag.name] } as SoundPackageQueryAttributes)
                                                    }}
                                                />
                                            </div>

                                        )
                                    })} */}

                                    </div>
                                    <div className="viewAll" onClick={() => {
                                        history.push('/labels/' + label.name)
                                    }}>
                                        View All
                                </div>
                                </div>
                            </div>

                        )
                    })}
                </div>}
        </div>
    )
}

export default LabelsComponent