import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { PagingInfo } from "./types";
import { Giveaway, GiveawayComment, GiveawayEntry } from "api/models/giveaway";
import { Environment } from "env";

export class GiveawayAPI {

    public static getGiveaways(platform: 'create' | 'supreme'): Promise<{ current: Giveaway[], past: Giveaway[] }> {
        let apiRequest = new AuthenticatedRequest('/giveaways/all', HTTPMethod.GET, APIVersion.v3_0, { platform });
        apiRequest.apiUrl = Environment.GENERIC_API
        return apiRequest.send().then((response) => {
            return response.data
        });
    }
    /**
     * Important: When using paging (View More), make sure to call array.unique('id') after appending the new comments to the array. It can happen that comments are duplicacted!
     * @param id 
     * @param paging 
     */
    public static getGiveawayComments(id: string, paging: PagingInfo): Promise<GiveawayComment[]> {
        let apiRequest = new AuthenticatedRequest('/giveaway/' + id + '/comments', HTTPMethod.GET, APIVersion.v3_0, paging);
        apiRequest.apiUrl = Environment.GENERIC_API
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static commentGiveaway(id: string, comment: string): Promise<GiveawayComment> {
        let apiRequest = new AuthenticatedRequest('/giveaway/' + id + '/comment', HTTPMethod.POST, APIVersion.v3_0, undefined, { comment });
        apiRequest.apiUrl = Environment.GENERIC_API
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static editComment(commentId: string, comment: string): Promise<GiveawayComment> {
        let apiRequest = new AuthenticatedRequest('/giveaway/comments/' + commentId, HTTPMethod.PUT, APIVersion.v3_0, undefined, { comment });
        apiRequest.apiUrl = Environment.GENERIC_API
        return apiRequest.send().then((res) => {
            return res.data
        });
    }

    public static likeComment(commentId: string): Promise<GiveawayComment> {
        let apiRequest = new AuthenticatedRequest('/giveaway/comments/' + commentId + '/like', HTTPMethod.POST, APIVersion.v3_0);
        apiRequest.apiUrl = Environment.GENERIC_API
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static deleteComment(commentId: string): Promise<void> {
        let apiRequest = new AuthenticatedRequest('/giveaway/comments/' + commentId, HTTPMethod.DELETE, APIVersion.v3_0);
        apiRequest.apiUrl = Environment.GENERIC_API
        return apiRequest.send().then(() => {
            return void 0;
        });
    }

    public static unLikeComment(commentId: string): Promise<GiveawayComment> {
        let apiRequest = new AuthenticatedRequest('/giveaway/comments/' + commentId + '/like', HTTPMethod.DELETE, APIVersion.v3_0);
        apiRequest.apiUrl = Environment.GENERIC_API
        return apiRequest.send().then((response) => {
            return response.data
        });
    }

    public static submit(giveawayId: string, zip: string, referral?: string): Promise<GiveawayEntry> {
        let apiRequest = new AuthenticatedRequest('/giveaway/' + giveawayId + '/enter/existing', HTTPMethod.POST, APIVersion.v3_0, undefined, { zip, referral });
        apiRequest.apiUrl = Environment.GENERIC_API
        return apiRequest.send().then((response) => {
            return response.data
        });
    }
}