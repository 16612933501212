import React from 'react'
import { RouteComponentProps, withRouter } from "react-router";
import { UsersAPI, MembershipInfo } from '../api/endpoints/user';
import { Loader, CardSale, Title, DefaultProps } from 'bpm-sounds-generic';
import { getAvailablePlans } from 'util/subscription';
import { Analytics } from '../manager/AnalyticsManager';

class Pricing extends React.Component<RouteComponentProps & DefaultProps, { loading: boolean, memberShipInfo?: MembershipInfo, trialApplicable: boolean }> {
    constructor(props: RouteComponentProps & DefaultProps) {
        super(props);
        this.state = {
            loading: true,
            trialApplicable: false
        }
    }

    componentDidMount() {
        Analytics.trackGeneric('Subscribe-Plans')
        UsersAPI.getMembership().then((info) => {
            this.setState({ memberShipInfo: info.activeSubscription, trialApplicable: info.trialApplicable, loading: false })
        }).catch(() => {
            this.setState({ trialApplicable: true, loading: false })
        })
    }

    render() {
        if (this.state.loading) {
            return <Loader centered />
        }
        const bf_plans = getAvailablePlans(
            this.state.memberShipInfo?.membership_info,
            this.state.trialApplicable).filter((p) => p.filter.eventType == 'black-friday')
        return (<div className="Membership">
            <Title titleText={'Manage Your Membership'} />
            {bf_plans.length > 0 && <><div className="sale-text sale">Black Friday & Cyber Monday Sale</div>
                <div className="cards-container">
                    {bf_plans.map((plan, index) => {
                        return <React.Fragment key={index}>
                            <CardSale {...plan} />
                        </React.Fragment>

                    })}
                </div>
            </>}
            <div className="regular-rate-text">Regular Membership Rates</div>
            <div className="cards-container">
                {getAvailablePlans(
                    this.state.memberShipInfo?.membership_info,
                    this.state.trialApplicable).filter(p => !p.filter.eventType).map((plan, index) => {
                        return <React.Fragment key={index}>
                            <CardSale {...plan} cardColors={'standard'} />
                        </React.Fragment>

                    })}
            </div >
        </div>
        );
    }

}

export default withRouter(Pricing)