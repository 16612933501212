import { AuthenticatedRequest, HTTPMethod, APIVersion } from "../requests/request";
import { Section } from "bpm-sounds-generic";

export class SectionAPI {

    public static getSection(location: string): Promise<Section[]> {
        let apiRequest = new AuthenticatedRequest<Section>('/sections', HTTPMethod.GET, APIVersion.v1_0, { location });

        return apiRequest.send().then((response) => {
            return <Section[]>response.data
        });
    }
}